import React, { useEffect, useState } from "react";
import {
  currencyFormat,
  makeJavolinGetHttpRequest,
} from "../../Shared/Utils/common";
import JavFormSelect from "../../Shared/Components/Forms/JavFormSelect";
import JavButton from "../../Shared/Components/Buttons/JavButton";
import AddWarehouseReview from "../Warehouse/AddWarehouse";
import AddNewInventoryDialog from "./Inventory/Dialogs/AddInventoryDialog";
import AgricWarehouse from "../Warehouse/AgricWarehouse";
import LoadingIcon from "../../Shared/Components/Icons/LoadingIcon";
import { useNavigate } from "react-router";

const InventoryDashboard = () => {
  const [activeTab, setActiveTab] = useState("home");
  let navigate = useNavigate();
  const [warehouseData, setWarehouseData] = useState({
    loading: false,
    data: null,
  });
  const [dashboard, setDashboard] = useState({
    loading: false,
    data: null,
  });
  const [requestDetails, setRequestDetails] = useState({
    hasOpen: false,
    data: null,
  });
  const [filterForm, setFilterForm] = useState({
    warehouse_id: null,
    commodity_id: null,
  });
  const [isOpen, setIsOpen] = useState({ hasOpen: false, id: null });

  const handleConfirm = (id) => {
    setIsOpen({ ...isOpen, hasOpen: true, id: id });
  };

  const closeModal = (id) => {
    setIsOpen({ ...isOpen, hasOpen: false, id: id });
  };

  const closeModalAdd = (id) => {
    setRequestDetails({ ...requestDetails, hasOpen: false, data: id });
  };

  const handleModal = (id) => {
    setRequestDetails({ ...requestDetails, hasOpen: true, data: id });
  };

  const inventorySummary = [
    {
      label: "Total Value",
      bgColor: "bg-jav-msc-color-500",
      icon: "/icons/TipJar.svg",
      key: "total_value",
    },
    {
      label: "Total Quantity(kg)",
      bgColor: "bg-jav-msc-color-400",
      icon: "/icons/ShoppingCart.svg",
      key: "total_weight",
    },
    {
      label: "Total Bags",
      bgColor: "bg-jav-msc-color-300",
      icon: "/icons/fi_3556920.svg",
      key: "total_bags",
    },

    {
      label: "Total Location",
      bgColor: "bg-jav-msc-color-600",
      icon: "/icons/Warehouse.svg",
      key: "total_warehouses",
    },
  ];

  const loadDashboard = () => {
    setDashboard({ ...dashboard, loading: true });
    makeJavolinGetHttpRequest(
      `business/agric/dashboard`,
      filterForm,
      (data) => {
        console.log("Data", data);
        setDashboard({ ...dashboard, loading: false, data: data });
      },
      (error) => {
        setDashboard({ ...dashboard, loading: false, error: error });
        console.log("error", error);
      }
    );
  };

  const loadWarehouses = () => {
    setWarehouseData({ ...warehouseData, loading: true });
    makeJavolinGetHttpRequest(
      "business/agric/warehouses",
      {},
      (data) => {
        const allWarehouseOption = { id: null, name: "All Warehouse" }; // Add this line
        setWarehouseData({
          ...warehouseData,
          loading: false,
          data: [allWarehouseOption, ...data], // Include "All Warehouse" option at the start
        });
        // setWarehouseData({...warehouseData, loading: false, data: data});
      },
      (error) => {
        setWarehouseData({ ...warehouseData, loading: false });
      }
    );
  };

  useEffect(() => {
    loadDashboard();
  }, [filterForm]);

  useEffect(() => {
    loadWarehouses();
  }, []);

  // navigate
  const onInventoryItemClicked = (inventory) => {
    navigate(`/inventory/${inventory?.id}`, { state: { name: inventory?.commodity?.code_name } });
  };

  return (
    <>
      {
        <div className="xl:px-20 lg:px-10 md:px-5 sm:px-5 px-4 py-3 overflow-hidden  overflow-y-auto">
          <div className="sm:flex  justify-between items-center">
            <div className="flex gap-6">
              <div
                onClick={() => {
                  setActiveTab("home");
                }}
                className={`${
                  activeTab === "home" ? "border-b-4" : ""
                } border-jav-primary-200 text-2xl rounded-b-sm ${
                  activeTab === "home" ? "font-satoshiBold" : "font-satoshi"
                } ${
                  activeTab === "home"
                    ? "text-jav-primary-200"
                    : "text-jav-active-600"
                } cursor-pointer`}
              >
                Home
              </div>
              <div
                onClick={() => {
                  setActiveTab("warehouse");
                }}
                className={`${
                  activeTab === "warehouse" ? "border-b-4" : ""
                } border-jav-primary-200 text-2xl rounded-b-sm ${
                  activeTab === "warehouse"
                    ? "font-satoshiBold"
                    : "font-satoshi"
                } ${
                  activeTab === "warehouse"
                    ? "text-jav-primary-200"
                    : "text-jav-active-600"
                }  cursor-pointer text-xl`}
              >
                Warehouse
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex mr-4 rounded-full cursor-pointer border border-jav-alert-blue-400 bg-white mt-5  px-7 text-xl font-satoshiBold ">
                <JavFormSelect
                  placeholder={"All Warehouse"}
                  hideBorder={true}
                  position={"bottom"}
                  className={"w-44"}
                  items={warehouseData?.data}
                  useNetwork={true}
                  onChange={(item) => {
                    console.log("....", item);

                    setFilterForm({
                      ...filterForm,
                      warehouse_id: item?.id !== null ? item?.id : null,
                    });
                  }}
                />
              </div>
              <div className="flex mr-4 rounded-full cursor-pointer border  bg-white border-jav-alert-blue-400 mt-5  px-7 text-xl font-satoshiBold ">
                <JavFormSelect
                  placeholder={"All products"}
                  common_code={"CT001"}
                  code={"CT001"}
                  hideBorder={true}
                  codeField={"code_name"}
                  position={"bottom"}
                  className={"w-36 text-jav-active-600"}
                  onChange={(item) => {
                    console.log("....", item);
                    setFilterForm({
                      ...filterForm,
                      commodity_id: item?.id,
                    });
                  }}
                />
              </div>
              <JavButton
                bgColor={"bg-jav-primary-200"}
                textColor="text-white"
                className={
                  "text-white text-sm h-9 cursor-pointer mt-5 py-7 px-7 sm:text-2xl rounded-full "
                }
                isLoading={false}
                onClick={() => {
                  activeTab === "home" ? handleConfirm("1") : handleModal("");
                }}
              >
                <span className="">
                  {activeTab === "home"
                    ? "Add new inventory"
                    : "Add new warehouse"}
                </span>
              </JavButton>
            </div>
          </div>

          {/* {       dashboard.loading ? <div className="h-screen w-screen flex justify-center place-items-center">
          <LoadingIcon className={`animate-spin h-10 w-10 mx-2 fill-current block`}/>
          </div>: 
      } 
          
           */}

          {activeTab === "home" && (
            <div className="mt-3 overflow-hidden">
              <div className="mb-10">
                <h2 className="text-3xl font-satoshiBold mb-4 text-jav-msc-color-200">
                  Inventory Summary
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-7">
                  {dashboard?.data?.summaries &&
                    Object.keys(dashboard?.data?.summaries)?.map(
                      (key, index) => {
                        let k = inventorySummary[index];
                        let item = dashboard?.data?.summaries?.[k.key];
                        return (
                          <div
                            key={index}
                            className={`${inventorySummary[index].bgColor} text-white p-4 rounded-lg shadow-md`}
                          >
                            <div className="flex justify-between items-start">
                              <div>
                                <div className="text-2xl font-satoshiBold pb-3">
                                  {index === 0 ? currencyFormat(item, 2) : item}
                                </div>
                                <div className="text-lg">
                                  {inventorySummary[index].label}
                                </div>
                              </div>
                              <img
                                className="text-lg"
                                src={inventorySummary[index].icon}
                              />
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
              </div>

              <div>
                <h2 className="text-3xl font-satoshiBold mb-4 text-jav-msc-color-200">
                  Inventory List
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-10 gap-y-4">
                  {dashboard?.data?.inventory?.map((item, index) => {
                    let image = JSON.parse(item?.commodity?.code_option);
                    return (
                      <div
                        key={index}
                        className="flex flex-col sm:flex-row bg-white p-4 rounded-lg shadow-md mb-4 w-full cursor-pointer"
                        onClick={() => onInventoryItemClicked(item)}
                      >
                        <img
                          src={image.image_url}
                          alt={item?.commodity?.code_name || "Commodity image"}
                          className="w-full sm:w-36 h-36 rounded-lg mb-4 sm:mb-0 sm:mr-4 object-cover"
                        />
                        <div className="flex-1 mt-2">
                          <div className="flex justify-between items-start">
                            <h3 className="text-2xl font-satoshiBold text-jav-msc-color-200 mb-2">
                              {item?.weight}
                              {item?.weight_unit}{" "}
                              {item?.commodity?.code_name ||
                                "Unknown Commodity"}
                            </h3>
                            <div className="ml-auto flex items-start sm:items-center"></div>
                          </div>

                          <div className="flex flex-col">
                            <div className="grid grid-cols-2 grid-flow-col  sm:flex-row gap-4 mt-3">
                              <span className="text-lg font-satoshiMedium">
                                Total bags:{" "}
                                <span className="text-xl font-satoshiBold text-black">
                                  {" "}
                                  {item?.package || 0}{" "}
                                  {item?.package_unit?.toLowerCase() || "units"}
                                </span>
                              </span>
                              <span className="text-lg font-satoshiMedium">
                                Total weight:
                                <span className="text-xl font-satoshiBold text-black">
                                  {" "}
                                  {item?.weight || 0}{" "}
                                  {item?.weight_unit?.toLowerCase() || "kg"}
                                </span>
                              </span>
                            </div>
                            <div className="flex gap-6 mt-4">
                              <div className="text-xl font-satoshiBold ">
                                {item?.warehouse?.name || "Unknown Warehouse"}
                              </div>
                              {" | "}
                              <div className="text-lg font-satoshiMedium text-jav-msc-color-800">
                                {item?.warehouse?.address || "Unknown Location"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          {activeTab === "warehouse" && (
            <div className="mt-4">
              <AgricWarehouse />
              {/* Add warehouses content here */}
            </div>
          )}
        </div>
      }
      <AddNewInventoryDialog
        open={isOpen.hasOpen}
        isLoading={false}
        isLoadingApprove={false}
        onApproveClicked={() => {}}
        onCloseClicked={() => {
          closeModal();
        }}
      />

      <AddWarehouseReview
        details={requestDetails.data}
        open={requestDetails.hasOpen}
        onDeclinedClick={() => {
          closeModalAdd();
        }}
        onCloseClicked={() => {
          closeModalAdd();
        }}
      />
    </>
  );
};

export default InventoryDashboard;
