export const ActionTypes = {
    REQUEST_ADD: "REQUEST_ADD",
    ADD_SUCCESS: "ADD_SUCCESS",
    ADD_ERROR: "ADD_ERROR",

    REQUEST_FETCH: "REQUEST_FETCH_LIST_SELECT",
    FETCH_SUCCESS: "FETCH_SUCCESS_LIST_SELECT",
    FETCH_ERROR: "FETCH_ERROR_LIST_SELECT",

    REQUEST_DELETE: "REQUEST_DELETE",
    DELETE_SUCCESS: "DELETE_SUCCESS",
    DELETE_ERROR: "DELETE_ERROR",

    REQUEST_STATS: "REQUEST_STATS_TAX_TYPE",
    STATS_ERROR: "STATS_ERROR_TAX_TYPE",
    STATS_SUCCESS: "STATS_SUCCESS_TAX_TYPE",


}