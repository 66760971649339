import React, { useState } from "react";
import { useNavigate } from "react-router";
import { makeJavolinRequest } from "../../Shared/Utils/common";
import JavInput from "../../Shared/Components/Forms/JavInput";
import JavFormSelect from "../../Shared/Components/Forms/JavFormSelect";
import JavButton from "../../Shared/Components/Buttons/JavButton";

const RegistrationForm = () => {
  const history = useNavigate();

  const [register, setRegister] = useState({
    loading: false,
  });
  const [form, setForm] = useState({
    file: null,
  });

  const handleChanges = (event) => {
    const value = event.target.value;
    setForm({
      ...form,
      [event.target.name]: value,
    });
  };

  const onRegisterClicked = () => {
    setRegister({ ...register, loading: true });
    makeJavolinRequest(
      {
        path: "business/register",
        method: "POST",
        data: { ...form, business_address: "business_address", currency_id: 2 },
      },
      null,
      null,
      (data) => {
        setRegister({ ...register, loading: false, data: data });
        alert("User Registered successfully");
        history("/login");
        console.log("data", data);
      },
      (error) => {
        setRegister({ ...register, loading: false, error: error });
        alert("Error: " + error);
        console.log("error", error);
      }
    );
  };

  return (
    <div className="flex bg-white overflow-hidden min-h-screen min-w-full  w-full md:w-3/4 lg:w-2/3 xl:w-2/3 hide-scrollbar">
        <div className="w-full md:w-1/2 p-8 lg:px-36 md:px-20  content-center">
          <div className="flex flex-col justify-center items-start">
            <img src="/logo/inv_logo.png" alt="Logo" className="h-24 mb-4" />
            <h2 className="text-6xl font-satoshiBold mb-2">Welcome</h2>
            <p className="text-gray-600 font-satoshiMedium text-2xl mb-1">
              Register to start using our Inventory System
            </p>
          </div>
          <div className="mt-8 grid gap-3 grid-cols-1">
            <JavInput
              title={"Name of Business"}
              name={"business_name"}
              value={register.business_name}
              onChange={handleChanges}
            />

            <JavFormSelect
              title={`Select Business Type`}
              placeholder={"Select Business Type "}
              common_code={"UAT001"}
              code={"UAT001"}
              codeField={"code_name"}
              position={"bottom"}
              showFirstOnly={true}
              onChange={(item) => {
                setForm({ ...form, business_type: item.id });
              }}
            />

            <JavInput
              title={"Full Name"}
              value={form.full_name}
              name={"full_name"}
              onChange={handleChanges}
            />

            <JavInput
              title={"Email"}
              name={"email"}
              value={form.email}
              onChange={handleChanges}
            />

            <JavInput
              title={"Phone Number"}
              name={"phone_number"}
              value={form.phone_number}
              onChange={handleChanges}
            />

            <JavInput
              title={"Password"}
              name={"password"}
              type={"password"}
              onChange={handleChanges}
              value={form.password}
            />

            <div
              className={"flex  font-proxima mt-2 items-center "}
            >
              <input type={"checkbox"} />
              <span className={"mx-1 text-sm"}>
                I agree to{" "}
                <span className={"text-jav-card-300"}>
                  Terms and Privacy Policy
                </span>
              </span>
            </div>

            <div className={"flex mt-1"}>
              <JavButton
                title={"Continue"}
                textColor={"text-white"}
                className={"w-full h-12 text-lg"}
                isLoading={register.loading}
                onClick={onRegisterClicked}
              />
            </div>
          </div>
          <p className="text-center text-lg font-satoshiBold text-gray-600 pt-2">
            Already have an account?{" "}
            <a className="text-green-500 mx-1 text-xl cursor-pointer" href="/login">
              Sign In
            </a>
          </p>
        </div>

        <div className="hidden md:block md:w-2/3 bg-cover bg-center p-4 hide-scrollbar">
          <div
            className="h-full rounded-lg overflow-hidden"
            style={{
              backgroundImage: `url('/images/bg/bg_2.png')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>

      </div>
  
  );
};

export default RegistrationForm;
