import { useEffect, useRef, useState } from "react";
import Dialog from "../../../../Shared/Components/Dialog/Dialog";
import JavFormSelect from "../../../../Shared/Components/Forms/JavFormSelect";
import JavInput from "../../../../Shared/Components/Forms/JavInput";
import ConfirmChangesDialog from "./ConfirmChangesDialog";
import { makeJavolinRequest } from "../../../../Shared/Utils/common";
import JavButton from "../../../../Shared/Components/Buttons/JavButton";

export default function EditInventoryDialog({ details, open, onCloseClicked, reload }) {
  const isMobile = window.innerWidth <= 768;
  const dialogWidth = isMobile ? "100%" : "40%";
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [form, setForm] = useState({
    commodity_id: null,
    warehouse_id: null,
    weight: "",
    unit_price_ncy: "",
    expected_stock_in_at: "",
    title: "",
    bags: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  // Effect to set form values when `details` change
  useEffect(() => {
    if (details) {
      setForm({
        commodity_id: details?.commodity_id || null,
        warehouse_id: details?.warehouse_id || null,
        weight: details?.weight || "",
        unit_price_ncy: details?.unit_price_ncy || "",
        expected_stock_in_at: details?.expected_stock_in_at || "",
        title: details?.title || "",
        bags: details?.package || "",
      });
    }
  }, [details]);

  // Handle input change for form fields
  const handleChanges = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  // Handle image selection
  const onImageSelected = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedFile({
          file: reader.result,
          meta: file,
          image: URL.createObjectURL(file),
        });
      };
      reader.onerror = (error) => alert("Error: " + error);
      reader.readAsDataURL(file);
    }
  };

  // Save item (PUT or POST)
  const onSaveItemClicked = () => {
    setIsLoading(true);
    const data = {
      ...form,
      file: selectedFile?.file,
      warehouse_id: form.warehouse_id || details?.warehouse?.id, // Use existing warehouse_id if not selected
      weight: parseFloat(form.weight).toFixed(2),
      weight_unit: details?.weight_unit,
      bags: form.bags && parseFloat(form.bags).toFixed(2),//details?.package || "",
      unit_price_ncy:parseFloat(form.unit_price_ncy).toFixed(2),

    };

    const method = details ? "PUT" : "POST";
    const path = `business/agric/inventories/${details?.id || ""}`;
    
    makeJavolinRequest(
      { path, method, data },
      null,
      null,
      (response) => {
        setIsLoading(false);
        alert(details ? "Item Updated Successfully" : "Item Uploaded Successfully", true, "SUCCESS");
        onCloseClicked();
        reload(); // Reload data after successful update
      },
      (error) => {
        setIsLoading(false);
        console.error("Error:", error);
        alert(`Update failed: ${error}`, false, "ERROR");
      }
    );
  };

  const productImage = details && JSON.parse(details?.commodity?.code_option)?.image_url;

  return (
    <>
      <ConfirmChangesDialog
        product={details}
        onClose={() => setIsConfirmOpen(false)}
        open={isConfirmOpen}
        isLoading={isLoading}
        onConfirm={() => {
          onSaveItemClicked();
          setIsConfirmOpen(false);
        }}
      />

      <Dialog
        position="center"
        style={{ width: dialogWidth }}
        open={open}
        onCloseClicked={onCloseClicked}
        title={<p className="mb-1 text-3xl py-3 px-3">Edit Inventory</p>}
      >
        <div className="flex flex-col px-5">
          <div className="flex gap-4">
            <div>
              {productImage && (
                <img
                  src={productImage}
                  alt={details?.commodity?.code_name}
                  className="h-36 object-cover rounded-lg"
                />
              )}
              <p className="text-left text-xl mt-2 font-satoshiBlack text-black">
                {details?.commodity?.code_name}
              </p>
            </div>
          </div>

          <div className="mb-6 mt-10 grid grid-cols-2 gap-4">
            <JavInput
              title="Quantity (KG)"
              type="number"
              name="weight"
              value={form.weight}
              onChange={handleChanges}
              placeholder="e.g. 15Kg"
              className="text-xl"
            />
            <JavInput
              title="Bags"
              type="number"
              name="bags"
              value={form.bags}
              onChange={handleChanges}
              placeholder="e.g. 15 bags"
              className="text-xl"
            />
          </div>

          <div className="mb-6 grid grid-cols-2 gap-4">
            <JavInput
              title="Unit Price"
              type="number"
              name="unit_price_ncy"
              value={form.unit_price_ncy}
              onChange={handleChanges}
              placeholder="e.g. $12"
              className="text-xl"
            />
            <JavInput
              disabled={true}
              title="Total Price"
              type="number"
              value={(form.unit_price_ncy * form.weight).toFixed(2)}
              className="text-xl"
            />
          </div>

          <div className="mb-6 grid grid-cols-2 gap-4">
            <JavFormSelect
              title="Warehouse"
              placeholder="Select warehouse"
              items={[
                { id: 1, title: "Javolin Main Warehouse" },
                { id: 2, title: "Jav Test Warehouse" },
              ]}
              position="bottom"
              onChange={(item) => setForm((prevForm) => ({ ...prevForm, warehouse_id: item.id }))}
            />

            <div className="mb-4">
              <h4 className="block font-satoshi text-xl text-black">Photos</h4>
              <input
                type="file"
                id="photos"
                ref={fileInputRef}
                className="w-full pl-2 pt-1 border rounded-sm h-10 text-sm"
                multiple
                onChange={onImageSelected}
                accept="image/*"
              />
            </div>
          </div>

          <JavButton
            title="Update Inventory"
            textColor="text-white"
            className="h-14 text-xl rounded-full"
            isLoading={isLoading}
            onClick={() =>{
              onCloseClicked()
              setIsConfirmOpen(true)
            }}
          />
        </div>
      </Dialog>
    </>
  );
}
