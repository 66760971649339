import React from "react";
import Dialog from "../../../../Shared/Components/Dialog/Dialog"; // Assuming you're using a custom Dialog component
import CloseIcon from "../../../../Shared/Components/Icons/CloseIcon";
import JavButton from "../../../../Shared/Components/Buttons/JavButton";

export default function ConfirmChangesDialog({
  open,
  onClose,
  product,
  isLoading,
  onConfirm,
}) {
  const dialogWidth = window.innerWidth <= 768 ? "90%" : "30%";
  const productImage = product && JSON.parse(product?.commodity?.code_option);

  // console.log("product", product);
  return (
    <Dialog
      open={open}
      onCloseClicked={onClose}
      style={{ width: dialogWidth }}
      position="center"
      no_title={true}
    >
      <div className="flex justify-end">
        <CloseIcon
          onClick={onClose}
          className={`stroke-current  shadow-lg text-black stroke-2  rounded-full cursor-pointer`}
        />
      </div>

      <div className="text-center pl-6 pr-6 pb-6">
        <h2 className="text-2xl font-satoshi text-black mb-4">
          Confirm Changes
        </h2>
        <div className="mb-4">
          <img
            src={productImage?.image_url}
            alt={product?.commodity?.code_name}
            className="w-24 h-24 rounded-full object-cover mx-auto"
          />
        </div>
        <p className="text-lg font-satoshiMedium text-black mb-2">
          {product?.commodity?.code_name}
        </p>
        <div className="flex justify-center items-center gap-8 mb-6">
          <p className="text-sm font-medium">
            Total bags:{" "}
            <span className="font-satoshiBold text-black">
              {product?.package ?? 0} {product?.package_unit}
            </span>
          </p>
          <p className="text-sm font-medium">
            Total weight:{" "}
            <span className="font-satoshiBold text-black">
              {product?.weight} {product?.package_unit}
            </span>
          </p>
        </div>
        <JavButton
          title="Confirm changes"
          className="w-full font-satoshiBold h-12 text-white rounded-full"
          textColor="white"
          onClick={onConfirm}
          isLoading={isLoading}
        />
      </div>
    </Dialog>
  );
}
