import CloseIcon from "../../../Shared/Components/Icons/CloseIcon";
import {useEffect, useState} from "react";
import JavInput from "../../../Shared/Components/Forms/JavInput";
import JavButton from "../../../Shared/Components/Buttons/JavButton";
import {useDispatch, useSelector} from "react-redux";
import {createAdminRole, createAdminUser, editAdminUser, getPermissions, getRoles} from "../duck/action";
import JavFormSelect from "../../../Shared/Components/Forms/JavFormSelect";

export default function CreateAccount(props) {

    const [roles,setRoles] = useState([]);
    const dispatch = useDispatch();
    const accountState = useSelector((state) => state.account)
    const isLoading = props.user ? accountState.accounts.edit.loading : accountState.accounts.create.loading;

    useEffect(() => {
        dispatch(getRoles());
    },[])


    useEffect(() => {
        if (accountState.roles.fetch.data) {
            setRoles(accountState.roles.fetch.data.map(item => {
                return {...item,title: item.name};
            }));
        }
    },[accountState.roles])

    useEffect(() => {
        if (props.user) {
            setForm({
                ...form,
                name: props.user.name,
                email: props.user.email,
                phone_number: props.user.phone_number || "",
                role_id: props.user.role && props.user.role.id || ""
            })
        }
    },[props.user])

    const [form,setForm] = useState({
        name: "",
        email: "",
        phone_number: "",
        role_id: ""
    });


    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const createUser = () => {
        if (props.user) {
            //edit User
            dispatch(editAdminUser(props.user.id,form))
        }else {
            //create user
            dispatch(createAdminUser(form))
        }
    }



    if (!props.open) {
        return <></>;
    }

    return (
        <div className={`absolute flex z-10 justify-center
                        items-center p-2 top-0 left-0
                        w-screen h-screen bg-gray/10
                        backdrop-blur-sm`}>

            <div className={"min-w-[30%] bg-white border rounded"}>

                <div className="border-b py-3 px-2 flex
                                items-center justify-between">

                    <h3 className="text-sm font-proximaBold
                                   text-blue-900 text-gray-700">
                        { props.title || "Create Admin Account" }
                    </h3>

                    <CloseIcon onClick={props.onCloseClicked} className={"cursor-pointer"}/>

                </div>


                <div className={"grid grid-cols-1 mb-10 dark:bg-[#242A38] dark:border-gray-600 gap-x-3 gap-y-4 py-4 px-4 my-2"}>

                    <JavInput
                        title={"name"}
                        name={"name"}
                        value={form.name}
                        onChange={handleChanges}
                    />

                    <JavInput
                        title={"Email"}
                        name={"email"}
                        value={form.email}
                        onChange={handleChanges}
                        disabled={props.user}
                    />

                    <JavInput
                        title={"Phone Number"}
                        name={"phone_number"}
                        value={form.phone_number}
                        onChange={handleChanges}
                    />


                    <JavFormSelect
                        items={roles}
                        position={"bottom"}
                        onChange={(item) => {
                            console.log(item);
                            setForm({
                                ...form,
                                role_id: item.id,
                            })
                        }}
                        title={"Select Role"}
                        className={""}
                    />





                    <div className={"flex justify-center mt-5"}>

                        <JavButton
                            title={props.user ? "Edit Account" :"Create Account"}
                            padding={"px-16 py-3"}
                            textColor={"text-white"}
                            isLoading={isLoading}
                            onClick={createUser}
                        />

                    </div>




                </div>






            </div>
        </div>
    )
}