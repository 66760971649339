import React, {useRef, useState} from "react";
import AddWarehouseReview from "./AddWarehouse";
import EditWarehouse from "./EditWarehouse";
import {useAlert} from "../../Shared/Context/AlertContext";
import {makeJavolinRequest} from "../../Shared/Utils/common";
import {CheckIcon, PencilIcon, TrashIcon} from "@heroicons/react/24/outline";
import CloseIcon from "../../Shared/Components/Icons/CloseIcon";
import JavButton from "../../Shared/Components/Buttons/JavButton";
import DefaultTable from "../../Shared/Components/Table/defaultTable";
import ConfirmDialog from "../../Shared/Components/Dialog/ConfirmDialog";

const AgricWarehouse = () => {
    const [isLoadingApprove, setLoadingApproval] = useState(false);
    const [requestLoading, setRequestLoading] = useState(false);
    const tableRef = useRef();
    const [requestDetails, setRequestDetails] = useState({
        hasOpen: false,
        data: null,
    });

    const {showAlert} = useAlert();

    const [isOpen, setIsOpen] = useState({hasOpen: false, id: null});
    const [isUpdateOpen, setUpdateOpen] = useState({hasOpen: false, id: null});

    const handleConfirm = (id) => {
        setIsOpen({...isOpen, hasOpen: true, id: id});
    };

    const handleCancel = () => {
        setIsOpen({...isOpen, hasOpen: false, id: null});
        tableRef?.current?.reloadTable();
    };

    const handleUpdateModalClose = () => {
        setUpdateOpen({...isOpen, hasOpen: false, id: null});
        tableRef?.current?.reloadTable();
    };
    const handleUpdateModal = (id) => {
        setUpdateOpen({...requestDetails, hasOpen: true, id: id});
    };


    const handleModal = (id) => {
        setRequestDetails({...requestDetails, hasOpen: true, data: id});
    };

    const closeModal = () => {
        setRequestDetails({...requestDetails, hasOpen: false, data: null});
        tableRef?.current?.reloadTable();
    };

    const declineRequest = (id) => {
        setRequestLoading(true);
        makeJavolinRequest(
            {
                path: `/business/agric/warehouses/${id}`,
                method: "DELETE",

            },
            null,
            null,
            (data) => {
                handleCancel();
                closeModal();
                setRequestLoading(false);
                showAlert("Request Deleted Successfully", "SUCCESS", <CheckIcon/>);

                console.log("data", data);
            },
            (error) => {
                setRequestLoading(false);
                handleCancel();
                closeModal();
                showAlert("Error: " + error, "FAILED", <CloseIcon/>);
                console.log("error", error);
            }
        );
    };

    return (
        <>
            <DefaultTable
                ref={tableRef}
                columns={["ID", "Warehouse Name", "Address", "Date Created", "Action"]}
                fields={[
                    "id",
                    "name",
                    "address",
                    "created_at",
                    {
                        render: (content) => {
                            return (
                                <div className={"flex justify-center"}>
                                    <JavButton
                                        bgColor={"bg-blue-600"}
                                        textColor="text-white"
                                        padding="2"
                                        icon={PencilIcon}
                                        className={"text-white  px-4 m-2 h-9"}
                                        onClick={() => {
                                            handleUpdateModal(content);
                                        }}
                                    >

                                        Edit
                                    </JavButton>

                                    <JavButton
                                        bgColor={"bg-red-600"}
                                        textColor="text-white"
                                        className={"text-white px-4 m-2 h-9 "}
                                        icon={TrashIcon}
                                        onClick={() => {
                                            handleConfirm(content.id);
                                        }}
                                    >
                                        Delete
                                    </JavButton>
                                </div>
                            );
                        },
                    },
                ]}
                link={"business/agric/warehouses"}
                title={`Warehouses`}
                tag={"api.table.warehouse"}
            />

            <ConfirmDialog
                isOpen={isOpen.hasOpen}
                handleCancel={handleCancel}
                message={
                    <span className="">
                        Are you sure you want to
                        <span className="font-satoshiBold pl-1 pr-1 text-black mx-1">Delete </span>
                        this Warehouse?
                    </span>
                }
                handleConfirm={() => {
                    declineRequest(isOpen.id);
                }}
                requestLoading={requestLoading}
            />

            <AddWarehouseReview
                details={requestDetails.data}
                open={requestDetails.hasOpen}
                onDeclinedClick={() => {
                    closeModal();
                }}
                isLoading={requestLoading}
                isLoadingApprove={isLoadingApprove}
                onApproveClicked={() => {
                }}
                onCloseClicked={() => {
                    closeModal();
                }}
            />
            <EditWarehouse
                details={isUpdateOpen.id}
                open={isUpdateOpen.hasOpen}
                onDeclinedClick={() => {
                    handleUpdateModalClose();
                }}
                isLoading={requestLoading}
                isLoadingApprove={isLoadingApprove}
                onApproveClicked={() => {

                }}
                onCloseClicked={() => {
                    handleUpdateModalClose();
                }}
            />
        </>
    );
};
export default AgricWarehouse;
