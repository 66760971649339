import {useEffect, useRef, useState} from "react";
import Dialog from "../../../../Shared/Components/Dialog/Dialog";
import JavFormSelect from "../../../../Shared/Components/Forms/JavFormSelect";
import JavInput from "../../../../Shared/Components/Forms/JavInput";
import {CheckIcon} from "@heroicons/react/24/outline";
import {makeJavolinRequest} from "../../../../Shared/Utils/common";
import JavButton from "../../../../Shared/Components/Buttons/JavButton";

export default function AddNewInventoryDialog(props) {
    const isMobile = window.innerWidth <= 768;
    const dialogWidth = isMobile ? "100%" : "40%";
    const [selectedProduct, setSelectedProduct] = useState("Coffee");
    const fileInputRef = useRef(null);
    const [selectFile, setSelectFile] = useState({});
    const [createNewItem, setCreateNewItem] = useState({
        data: null,
        loading: false,
    });
    const [commodities, setCommodities] = useState(null);

    const [form, setForm] = useState({
        commodity_id: null,
        warehouse_id: null,
        weight: null,
        unit_price_ncy: null,
        expected_stock_in_at: null,
        title: null,
    });

    const resetForm = () => {
        setForm(Object.fromEntries(Object.keys(form).map((key) => [key, ""])));
    };

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value,
        });
    };

    function getBase64(file, onSuccess, onError) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => onSuccess(reader.result);
        reader.onerror = (error) => onError(error);
    }

    const onUploadImageClicked = () => {
        fileInputRef?.current?.click();
    };

    const onImageSelected = (event) => {
        console.log("onImageSelected", event.target.files[0]);

        getBase64(
            event.target.files[0],
            (fileContent) => {
                setSelectFile({
                    ...selectFile,
                    file: fileContent,
                    meta: event.target.files[0],
                    image: URL.createObjectURL(event.target.files[0]),
                });
            },
            (error) => {
                alert("Error: " + error);
            }
        );
    };

    const onCreateNewItemClicked = () => {
        setCreateNewItem({...createNewItem, loading: true});
        makeJavolinRequest(
            {
                path: `business/agric/inventories`,
                method: "POST",
                data: {
                    ...form,
                    file: selectFile?.file,
                    warehouse_id: 1,
                    weight: parseFloat(form?.weight)?.toFixed(2),
                    weight_unit: "KG",
                },
            },
            null,
            null,
            (data) => {
                setCreateNewItem({...createNewItem, loading: false, data: data});
                resetForm();
                props?.onCloseClicked();
                alert("Item Uploaded Successfully");
            },
            (error) => {
                setCreateNewItem({...createNewItem, loading: false});
                console.log("error", error);
            }
        );

        console.log("onCreateNewItemClicked", form);
    };

    const fetchCommonCodes = () => {
        makeJavolinRequest(
            {
                path: "business/common-codes?group=CT001",
                method: "GET",
            },
            null,
            null,
            (data) => {
                setCommodities(data);
                console.log(data);
            },
            (error) => {
                console.log("error is ", error);
            }
        );
    };

    useEffect(() => {
        fetchCommonCodes();
    }, [props?.code]);

    return (
        <Dialog
            position={"center"}
            style={{width: dialogWidth}}
            open={props?.open}
            onCloseClicked={props?.onCloseClicked}
            review={false}
            title={
                <>
                    <p className="mb-1 text-3xl py-3 px-3">Add new inventory</p>
                </>
            }
        >
            <div className={"flex flex-col px-5"}>
                <div>
                    <h3
                        className={
                            "font-satoshiBold text-2xl sm:w-text-sm text-black"
                        }
                    >
                        Select product

                    </h3>
                    <div className="flex gap-4 ">
                        {commodities?.map((product, index) => {
                            let codeOption = JSON.parse(product?.code_option ?? "{}");
                            return (
                                <div key={index}>
                                    <div
                                        className={`border-4 rounded-lg ${
                                            selectedProduct === product?.code_name
                                                ? "border-jav-active-500"
                                                : "border-white"
                                        } cursor-pointer`}
                                    >
                                        {selectedProduct === product?.code_name ? (
                                            <div
                                                className={`absolute mt-2 ml-2 h-4 w-4 place-content-center rounded-full bg-white`}
                                            >
                                                <CheckIcon height={15} className="items-center"/>
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                        <img
                                            onClick={() => {
                                                setForm({...form, commodity_id: product?.id});
                                                // console.log(product?.id )
                                                setSelectedProduct(product.code_name);
                                            }}
                                            src={codeOption?.image_url}
                                            alt={product?.code_name}
                                            className={`h-36 object-cover rounded-lg `}
                                        />
                                    </div>

                                    <p
                                        className={`text-left text-xl mt-2 ${
                                            selectedProduct === product?.code_name
                                                ? "font-satoshiBlack text-black"
                                                : ""
                                        }`}
                                    >
                                        {product?.code_name}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="mb-6 grid grid-cols-2 gap-4 mt-10">
                    <JavInput
                        title={"Quantity (KG)"}
                        type={"number"}
                        name={"weight"}
                        value={form?.weight}
                        onChange={handleChanges}
                        placeholder={"eg 15Kg"}
                        className="text-xl"
                    />
                    <JavInput
                        title={"Bags"}
                        type={"number"}
                        name={"bags"}
                        value={form?.package}
                        onChange={handleChanges}
                        placeholder={"eg 15 bags"}
                        className="text-xl"
                    />
                </div>

                <div className="mb-6 grid grid-cols-2 gap-4">
                    <JavInput
                        title={"Unit Price"}
                        type={"number"}
                        name={"unit_price_ncy"}
                        value={form?.unit_price_ncy}
                        onChange={handleChanges}
                        placeholder={"eg $12"}
                        className="text-xl"
                    />

                    <JavInput
                        disabled={true}
                        title={"Total Price"}
                        type={"number"}
                        onChange={handleChanges}
                        value={form?.unit_price_ncy * form?.weight}
                        className="text-xl"
                        name={"total_price"}
                    />
                </div>

                <div className="mb-6 grid grid-cols-2 gap-4">
                    <div className="mb-6 mt-1">
                        <div className={"col-span-2 mt-2"}>
                            <JavFormSelect
                                title={`Warehouse`}
                                placeholder={"Select warehouse "}
                                className="text-xl"
                                items={[
                                    {
                                        title: "Javolin Main Warehouse",
                                    },
                                    {
                                        title: "Jav Test Warehouse",
                                    },
                                ]}
                                position={"bottom"}
                                onChange={(item) => {
                                }}
                            />
                        </div>
                    </div>

                    <div className="mb-4 mt-3">
                        <h3
                            htmlFor="photos"
                            className="block font-satoshi text-xl text-black"
                        >
                            Photos
                        </h3>
                        <input
                            type="file"
                            id="photos"
                            className="w-full border rounded-sm h-10 text-center pl-2 pt-1 text-sm items-center"
                            multiple
                            onClick={onUploadImageClicked}
                            accept="image/*"
                            capture={"environment"}
                            onChange={onImageSelected}
                        />
                    </div>
                </div>

                <JavButton
                    title={"Add inventory"}
                    textColor={"text-white"}
                    className="h-14 text-xl rounded-full"
                    isLoading={createNewItem.loading}
                    onClick={onCreateNewItemClicked}
                />
            </div>
        </Dialog>
    );
}
