import React, { useState } from "react";
import Dialog from "../../../../Shared/Components/Dialog/Dialog"; // Assuming you're using a custom Dialog component
import CloseIcon from "../../../../Shared/Components/Icons/CloseIcon";
import { listTile } from "../InventoryDetailsPage";
import JavFormSelect from "../../../../Shared/Components/Forms/JavFormSelect";
import JavButton from "../../../../Shared/Components/Buttons/JavButton";
import { makeJavolinRequest } from "../../../../Shared/Utils/common";

export default function DeleteInventoryDialog({
  open,
  onClose,
  product,
  reload,
}) {
  const dialogWidth = window.innerWidth <= 768 ? "80%" : "30%";
  const [isLoading, setIsLoading] = useState(false);

  const onDeleteItem = () => {
    setIsLoading(true);

    const method = "DELETE";
    const path = `business/agric/inventories/${product?.id || ""}`;

    makeJavolinRequest(
      { path, method },
      null,
      null,
      (response) => {
        setIsLoading(false);
        alert("Inventory deleted successfully")
        reload();

      },
      (error) => {
        setIsLoading(false);
        console.error("Error:", error);
        // Alert(`Update failed: ${error}`, false, "ERROR");
      }
    );
  };
  return (
    <Dialog
      open={open}
      onCloseClicked={onClose}
      style={{ width: dialogWidth }}
      position="center"
      title="Delete inventory"
    >
      <div className="text-center pl-6 pr-6 pb-6">
        <div className="flex items-start w-full  rounded-md text-left border px-2 py-1 bg-jav-alert-blue-600 border-jav-alert-blue-500 text-xs font-satoshi text-black">
          <img src="/icons/Warning.svg" alt="Warning" className="pt-1 pr-2" />
          <div>
            <span className="font-satoshiBold text-sm text-black">
              Warning:
            </span>{" "}
            Deleting this item will permanently remove it and its entire history
            from your inventory.
          </div>
        </div>

        <div className="flex space-x-5 mt-5">
          {listTile(
            "Product name",
            `${product?.commodity?.code_name ?? "N/A"}`, true
          )}
          {listTile(
            "Total bags",
            `${product?.package ?? "N/A"} ${product?.package_unit ?? "N/A"}`, true
          )}
          {listTile(
            "Total weight",
            `${product?.weight ?? "N/A"} ${product?.weight_unit ?? "N/A"}`, true
          )}
          {listTile("Status", `${"In Stock"}`, true)}
        </div>

        <div className="w-full border mt-3 mb-5" />
        <div className="space-y-4 mb-6">
          <h4 className="text-left font-satoshi text-black">
            To confirm deletion, select reason for deleting
          </h4>

          <JavFormSelect
            title={``}
            placeholder={"Select reason "}
            items={["Select reason","Item Sold", "Item Expired", "Others"]}
            position={"bottom"}
            onChange={(item) => {}}
          />
        </div>

        {/* <div className="flex justify-center items-center gap-8 mb-6">
          <p className="text-sm font-medium">
            Total bags:{" "}
            <span className="font-satoshiBold text-black">
              {product?.package ?? 0} {product?.package_unit}
            </span>
          </p>
          <p className="text-sm font-medium">
            Total weight:{" "}
            <span className="font-satoshiBold text-black">
              {product?.weight} {product?.package_unit}
            </span>
          </p>
        </div> */}
        <JavButton
          title="Delete inventory"
          className="w-full font-satoshiBold h-12 text-white rounded-full"
          textColor="white"
          onClick={onDeleteItem}
          isLoading={isLoading}
        />
      </div>
    </Dialog>
  );
}
