import { ActionTypes } from "./type";
import Cookies from "js-cookie";
import { makeJavolinRequest } from "../../../Shared/Utils/common";

export const login = (email, password) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_LOGIN });
        makeJavolinRequest({
            path: "business/login",
            method: "POST",
            data: {
                email: email,
                password: password
            }
        },{
            SUCCESS: ActionTypes.LOGIN_SUCCESS,
            ERROR: ActionTypes.LOGIN_ERROR
        }, dispatch, (data) => {
            if (data && data.token) {
                Cookies.set("javAdminAccessToken", data.token.access_token, {
                    expires: 7,
                });
                Cookies.set("javAdmins", JSON.stringify(data.user), {
                    expires: 7,
                });
                Cookies.set("javPermissions", JSON.stringify(data.permissions.map(item => {
                    return item.name
                })), {
                    expires: 7,
                });

                // Persist user data in local storage
                localStorage.setItem("user", JSON.stringify(data.user));
                localStorage.setItem("permissions", JSON.stringify(data.permissions.map(item => item.name)));
            }
        });
    };
};

export const setUser = (userData) => {
  return {
      type: ActionTypes.LOGIN_SUCCESS, // Or a different action type if preferred
      payload: {
          user: userData.user,
          permissions: userData.permissions,
          token: Cookies.get("javAdminAccessToken") // Optionally include the token from cookies
      }
  };
};

export const logout = () => {
    return async (dispatch) => {
        Cookies.remove("javAdminAccessToken");
        Cookies.remove("javAdmin");

        // Clear local storage on logout
        localStorage.removeItem("user");
        localStorage.removeItem("permissions");

        window.location.reload();
    };
};
