import React, { useEffect, useState, useCallback, useRef } from "react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate, useParams } from "react-router";
import LoadingIcon from "../../../Shared/Components/Icons/LoadingIcon";
import { makeJavolinRequest } from "../../../Shared/Utils/common";
import EditInventoryDialog from "../Inventory/Dialogs/EditInventoryDialog";
import DeleteInventoryDialog from "./Dialogs/DeleteinventoryDailog";
import EditIcon, { Edit } from "../../../Shared/Components/Icons/EditIcon";
import JavButton from "../../../Shared/Components/Buttons/JavButton";
import DefaultTable from "../../../Shared/Components/Table/defaultTable";

const InventoryDetailsPage = () => {
  const [dashboard, setDashboard] = useState({ loading: false, product: null });
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [isReload, setReload] = useState(false);
  const navigate = useNavigate();
  const inventoryId = useParams();
  const tableRef = useRef();
  let location = useLocation();

  const loanReference = window.location.href.split("/")?.[5];

  const handleModalOpen = useCallback(() => setIsOpen(true), []);
  const handleModalClose = useCallback(() => setIsOpen(false), []);
  const handleDeleteModalOpen = useCallback(() => setDeleteOpen(true), []);
  const handleDeleteModalClose = useCallback(() => setDeleteOpen(false), []);

  const loadDashboard = useCallback(() => {
    setDashboard({ loading: true, product: null });
    makeJavolinRequest(
      {
        path: `business/agric/inventories/${inventoryId?.id || ""}`,
        method: "GET",
      },
      null,
      null,
      (data) => {
        setDashboard({ loading: false, product: data });
      },
      (error) => {
        setDashboard({ loading: false });
        console.error("Error:", error);
      }
    );
  }, [loanReference]);

  useEffect(() => {
    loadDashboard();
  }, [loadDashboard, isReload]);

  // console.log("=====================", );

  const product = dashboard?.product;
  const image = product && JSON.parse(product?.commodity?.code_option);

  const handleReload = useCallback(() => setReload((prev) => !prev), []);

  return (
    <>
      <EditInventoryDialog
        details={product}
        open={isOpen}
        onCloseClicked={handleModalClose}
        reload={() => {
          handleReload();
          tableRef?.current?.reloadTable();
        }}
      />

      <DeleteInventoryDialog
        product={product}
        open={isDeleteOpen}
        onClose={handleDeleteModalClose}
        reload={() => {
          navigate(-1);
          tableRef?.current?.reloadTable();
        }}
      />

      {dashboard.loading ? (
        <div className="h-screen w-screen flex justify-center items-center">
          <LoadingIcon
            className={`animate-spin h-10 w-10 mx-2 fill-current block`}
          />{" "}
        </div>
      ) : (
        <div className="px-4 sm:px-8 md:px-24 py-20 sm:py-9 bg-jav-alert-blue-200">
          <div className="bg-white rounded-2xl shadow-md p-4 sm:p-6">
            <div className="flex flex-col sm:flex-row justify-between items-start">
              <div className="flex items-center space-x-5">
                <h1 className="text-4xl mb-0 font-satoshiBold text-gray-800">
                  {dashboard?.product?.commodity?.code_name} {product?.weight}{" "}
                  KG
                </h1>
                <div className="w-0.5 bg-jav-alert-blue-400 h-6"></div>
                <span className="inline-block items-center bg-purple-100 text-purple-600 rounded-full px-3 py-1 text-sm font-satoshi">
                  In-stock
                </span>
              </div>
              <div className="flex gap-2">
                <JavButton
                  bgColor="bg-blue-700"
                  textColor="text-white"
                  padding="2"
                  className="px-4 m-2 h-9 cursor-pointer"
                  onClick={handleModalOpen}
                >
                  Edit{" "}
                  <Edit
                    width="20"
                    color={"white"}
                    height="20"
                    className="ml-2"
                  />
                </JavButton>

                <JavButton
                  bgColor="bg-red-700"
                  textColor="text-white"
                  className="px-4 m-2 h-9 cursor-pointer"
                  onClick={handleDeleteModalOpen}
                >
                  Delete{" "}
                  <TrashIcon
                    width="20"
                    color="white"
                    height="20"
                    className="ml-2"
                  />
                </JavButton>
              </div>
            </div>

            <div className="flex flex-col md:flex-row mt-6">
              <img
                src={image?.image_url}
                alt={product?.commodity?.code_name}
                className="rounded-lg w-full md:w-96 h-auto"
              />
              <div className="ml-0 md:ml-6 mt-4 md:mt-0 space-y-5">
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
                  {listTile(
                    "Total bags",
                    `${product?.package ?? "N/A"} ${
                      product?.package_unit ?? ""
                    }`
                  )}
                  {listTile(
                    "Total weight",
                    `${product?.weight} ${product?.weight_unit}`
                  )}
                  {listTile("Status", "In-Stock")}
                </div>
                {listTile("Location", product?.warehouse?.name)}
                {listTile("Notes / description", "N/A")}
              </div>
            </div>

            <div className="mt-8">
              <h2 className="text-lg sm:text-xl font-semibold text-gray-800">
                Product history
              </h2>
              <div className="overflow-x-auto mt-4">
                <DefaultTable
                  ref={tableRef}
                  columns={[
                    "ID",
                    "PRODUCT",
                    "TOTAL BAGS",
                    "TOTAL WEIGHT (KG)",
                    "TIMESTAMP",
                    "PROGRESS STAT",
                  ]}
                  fields={[
                    "id",
                    {
                      render: (content) => {
                        return (
                          <td className="flex justify-center">
                            <div className="whitespace-nowrap pl-3 font-satoshiBold">
                              {location?.state?.name}
                            </div>
                          </td>
                        );
                      },
                    },
                    "package",
                    "weight",
                    "updated_at",
                    {
                      render: (content) => {
                        return (
                          <td className="flex justify-center items-center ">
                            <div className="whitespace-nowrap justify-center items-center pl-3 font-satoshiBold place-content-center px-4 rounded-full py-1 bg-[#EAFAE5] text-[#3DA31F]">
                              {content.in_stock ===1 ? "In Stock":"Out of Stock"}
                            </div>
                          </td>
                        );
                      },
                    },

                  ]}
                  link={`business/agric/inventories/${inventoryId?.id}/history`}
                  title={"History List"}
                  tag={"api.table.history"}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InventoryDetailsPage;

export const listTile = (title, desc, toDel) => (
  <div className="text-left mb-2">
    <p
      className={`font-satoshi ${
        toDel ? "text-sm" : "text-2xl"
      } text-jav-active-600`}
    >
      {title}
    </p>
    <h4
      className={`font-satoshiBold  ${
        toDel ? "text-sm" : "text-2xl"
      } text-black`}
    >
      {desc}
    </h4>
  </div>
);
