export const ActionTypes = {


    REQUEST_FETCH_ROLES: "REQUEST_FETCH_ROLES",
    FETCH_SUCCESS_ROLES: "FETCH_SUCCESS_ROLES",
    FETCH_ERROR_ROLES: "FETCH_ERROR_ROLES",

    REQUEST_CREATE_ROLES: "REQUEST_CREATE_ROLES",
    CREATE_SUCCESS_ROLES: "CREATE_SUCCESS_ROLES",
    CREATE_ERROR_ROLES: "CREATE_ERROR_ROLES",


    REQUEST_FETCH_PERMISSIONS: "REQUEST_FETCH_PERMISSIONS",
    FETCH_SUCCESS_PERMISSIONS: "FETCH_SUCCESS_PERMISSIONS",
    FETCH_ERROR_PERMISSIONS: "FETCH_ERROR_PERMISSIONS",


    REQUEST_FETCH_ACCOUNT: "REQUEST_FETCH_ACCOUNT",
    FETCH_SUCCESS_ACCOUNT: "FETCH_SUCCESS_ACCOUNT",
    FETCH_ERROR_ACCOUNT: "FETCH_ERROR_ACCOUNT",

    REQUEST_CREATE_ACCOUNT: "REQUEST_CREATE_ACCOUNT",
    CREATE_SUCCESS_ACCOUNT: "CREATE_SUCCESS_ACCOUNT",
    CREATE_ERROR_ACCOUNT: "CREATE_ERROR_ACCOUNT",

    REQUEST_EDIT_ACCOUNT: "REQUEST_EDIT_ACCOUNT",
    CREATE_SUCCESS_EDIT: "CREATE_SUCCESS_EDIT",
    EDIT_ERROR_ACCOUNT: "EDIT_ERROR_ACCOUNT",


    REQUEST_DELETE_ACCOUNT: "REQUEST_DELETE_ACCOUNT",
    DELETE_SUCCESS_ACCOUNT: "DELETE_SUCCESS_ACCOUNT",
    DELETE_ERROR_ACCOUNT: "DELETE_ERROR_ACCOUNT",


    REQUEST_RESET_ACCOUNT: "REQUEST_RESET_ACCOUNT",
    RESET_SUCCESS_ACCOUNT: "RESET_SUCCESS_ACCOUNT",
    RESET_ERROR_ACCOUNT: "RESET_ERROR_ACCOUNT",



}