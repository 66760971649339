import {combineReducers} from "redux"
import {AuthReducer} from "../Modules/Login/duck/reducer";
import {TableReducer} from "../Shared/Components/Table/duck/reducer";
import {ListSelectReducer} from "../Shared/Components/Dialog/ListSelectDialog/duck/reducer";
import {AccountReducer} from "../Modules/AccountManagement/duck/reducer";
import {FormsReducer} from "../Shared/Components/Forms/duck/reducer";

const reducers = combineReducers({
    auth: AuthReducer,
    table: TableReducer,
    list: ListSelectReducer,
    forms: FormsReducer,
    account: AccountReducer,
});


export default reducers;