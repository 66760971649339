import {ActionTypes} from "./type";
import {makeJavolinRequest} from "../../../Shared/Utils/common";


export const getPermissions = () => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_FETCH_PERMISSIONS });
        makeJavolinRequest({
            path: "admin-portal/permissions",
            method: "GET"
        },{
            SUCCESS: ActionTypes.FETCH_SUCCESS_PERMISSIONS,
            ERROR: ActionTypes.FETCH_ERROR_PERMISSIONS
        },dispatch);
    }
}

export const getRoles = () => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_FETCH_ROLES });
        makeJavolinRequest({
            path: "admin-portal/roles",
            method: "GET"
        },{
            SUCCESS: ActionTypes.FETCH_SUCCESS_ROLES,
            ERROR: ActionTypes.FETCH_ERROR_ROLES
        },dispatch);
    }
}


export const deleteAccount = (id) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_DELETE_ACCOUNT });
        makeJavolinRequest({
            path: `admin-portal/admin/accounts/${id}`,
            method: "DELETE"
        },{
            SUCCESS: ActionTypes.DELETE_SUCCESS_ACCOUNT,
            ERROR: ActionTypes.DELETE_ERROR_ACCOUNT
        },dispatch);
    }
}

export const resetAccount = (id) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_DELETE_ACCOUNT });
        makeJavolinRequest({
            path: `admin-portal/admin/accounts/${id}/reset`,
            method: "POST"
        },{
            SUCCESS: ActionTypes.DELETE_SUCCESS_ACCOUNT,
            ERROR: ActionTypes.DELETE_ERROR_ACCOUNT
        },dispatch);
    }
}



export const deleteRole = () => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_DELETE_ACCOUNT });
        makeJavolinRequest({
            path: "admin-portal/admin/roles",
            method: "DELETE"
        },{
            SUCCESS: ActionTypes.DELETE_SUCCESS_ACCOUNT,
            ERROR: ActionTypes.DELETE_ERROR_ACCOUNT
        },dispatch);
    }
}


export const createAdminRole = (data) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_CREATE_ROLES });
        makeJavolinRequest({
            path: "admin-portal/roles",
            method: "POST",
            data: data
        },{
            SUCCESS: ActionTypes.CREATE_SUCCESS_ROLES,
            ERROR: ActionTypes.CREATE_ERROR_ROLES
        },dispatch);
    }
}

export const createAdminUser = (data) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_CREATE_ACCOUNT });
        makeJavolinRequest({
            path: "admin-portal/admin/accounts",
            method: "POST",
            data: data
        },{
            SUCCESS: ActionTypes.CREATE_SUCCESS_ACCOUNT,
            ERROR: ActionTypes.CREATE_ERROR_ACCOUNT
        },dispatch);
    }
}

export const editAdminUser = (id,data) => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_EDIT_ACCOUNT });
        makeJavolinRequest({
            path: `admin-portal/admin/accounts/${id}`,
            method: "PUT",
            data: data
        },{
            SUCCESS: ActionTypes.CREATE_SUCCESS_EDIT,
            ERROR: ActionTypes.EDIT_ERROR_ACCOUNT
        },dispatch);
    }
}



export const getAccounts = () => {
    return async function(dispatch) {
        dispatch({ type: ActionTypes.REQUEST_FETCH_ACCOUNT });
        makeJavolinRequest({
            path: "admin-portal/accounts",
            method: "GET"
        },{
            SUCCESS: ActionTypes.FETCH_SUCCESS_ACCOUNT,
            ERROR: ActionTypes.FETCH_ERROR_ACCOUNT
        },dispatch);
    }
}


