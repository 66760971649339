import CloseIcon from "../../../Shared/Components/Icons/CloseIcon";
import {useEffect, useState} from "react";
import JavInput from "../../../Shared/Components/Forms/JavInput";
import JavButton from "../../../Shared/Components/Buttons/JavButton";
import {useDispatch, useSelector} from "react-redux";
import {createAdminRole, getPermissions} from "../duck/action";

export default function CreateRole(props) {

    const dispatch = useDispatch();
    const [permissions,setPermissions] = useState([])
    const accountState = useSelector((state) => state.account)
    const isLoading = accountState.roles.create.loading; 

    useEffect(() => {
        dispatch(getPermissions());
    },[])


    useEffect(() => {
        if (accountState.permissions.fetch.data) {
            setPermissions(accountState.permissions.fetch.data.map(item => {
                item["active"] = false;
                return item;
            }))
        }
        console.log(accountState.permissions)
    },[accountState.permissions])

    const onItemClick = (index) => {
        let newArr = [...permissions];
        newArr[index] = {...newArr[index],active: !newArr[index].active}
        console.log(newArr)
        setPermissions(newArr);
    }

    const [form,setForm] = useState({
        name: ""
    });


    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value
        })
    }

    const createRole = () => {
        dispatch(createAdminRole({
            name: form.name,
            permissions: JSON.stringify(permissions.filter(item => item.active).map(item => item.id))
        }))
    }

    if (!props.open) {
        return <></>;
    }

    return (
        <div className={`absolute flex z-10 justify-center
                        items-center p-2 top-0 left-0
                        w-screen h-screen bg-gray/10
                        backdrop-blur-sm`}>

            <div className={"min-w-[50%] bg-white border rounded"}>

                <div className="border-b py-3 px-2 flex
                                items-center justify-between">

                    <h3 className="text-sm font-proximaBold
                                   text-blue-900 text-gray-700">
                        { props.title || "Create Role" }
                    </h3>

                    <CloseIcon onClick={props.onCloseClicked} className={"cursor-pointer"}/>

                </div>


                <div className={"grid grid-cols-1 mb-10 dark:bg-[#242A38] dark:border-gray-600 gap-x-0 gap-y-0 py-4 px-4 my-2"}>

                    <JavInput
                        title={"name"}
                        name={"name"}
                        value={form.name}
                        onChange={handleChanges}
                    />

                    <h3 className={`text-sm font-proximaBold pt-5 text-gray-600`}>Select Permissions</h3>

                    <div className={"grid grid-cols-3 gap-2 "}>

                        {
                            permissions.map((item,index) => {
                                return (
                                    <div onClick={() => {
                                        onItemClick(index)
                                    }} className={`border text-sm rounded font-bold ${item.active ? 'bg-green-500 text-white' : ''} cursor-pointer text-center p-2`}>
                                        {item.name}
                                    </div>
                                )
                            })
                        }



                    </div>


                    <div className={"flex justify-center mt-5"}>

                        <JavButton
                            title={"Create Role"}
                            padding={"px-16 py-3"}
                            textColor={"text-white"}
                            isLoading={isLoading}
                            onClick={createRole}
                        />

                    </div>




                </div>






            </div>
        </div>
    )
}