import NavBar from "../../Shared/Components/Nav/NavBar";
import Toolbar from "../../Shared/Toolbar";
import {Outlet, useLocation, useNavigate, useRoutes} from "react-router-dom";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Alert from "../../Shared/Components/Alert";
import ChatBubbleIcon from "../../Shared/Components/Icons/ChatBubbleIcon";
import JavChat from "../../Shared/Components/Chat";

export default function AppLayout() {
    const location = useLocation();
    const navigate = useNavigate();
    const [theme,setTheme] = useState(localStorage.theme || "white");
    const isAuth = !!useSelector((state) => state.auth.login.token);
    const [currentPath,setCurrentPath] = useState(null);

    const changeModel = () => {
        if (localStorage.theme === undefined || localStorage.theme === "dark") {
            localStorage.theme = "white";
            setTheme("white")
            document.documentElement.classList.remove('dark');
        }else {
            localStorage.theme = "dark";
            setTheme("dark");
            document.documentElement.classList.add('dark');
        }
    }

    useEffect(() => {
        if (location.pathname === "/") {
            navigate(isAuth ? "/dashboard" : "/login")
        }
        if (currentPath && currentPath !== location?.pathname) {
            //this previous page
        }
        setCurrentPath(location?.pathname);
    },[location]);



    return (

        <div
            className={
                "tw-min-h-screen top-0  tw-flex tw-w-screen tw-h-screen "
            }
        >
            <Alert/>

            <div className="tw-relative tw-flex-1 tw-overflow-hidden tw-overflow-y-auto tw-overflow-x-hidden">
                <Toolbar/>

                <div className="md:tw-mt-6 tw-mx-1 md:tw-mx-4">
                    <Outlet />
                </div>
            </div>
        </div>

    )
}