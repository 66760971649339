import axios from "axios";
import {API_BASE_URL} from "./constants";
import Cookies from "js-cookie";

export const makeJavolinRequest =  (request, dispatchVariables , dispatch, onSuccess = null,onError =  null,tag = null) => {
    let options = {
        method: request.method,
        url: API_BASE_URL + request.path,
        data: request.data,
        headers: {
            Authorization: "Bearer "+ Cookies.get("javAdminAccessToken")
        },
        onDownloadProgress: process => {
            let percentCompleted = Math.round(
                (process.loaded * 100) / process.total
            );
            // console.log("download completed : ",percentCompleted)
        },
        onUploadProgress: progress => {
            console.log("onUploadProgress -> ",progress);
        }
    }
    if (request.query) {
        options = {
            ...options,
            params: request.query
        }
    }
    axios.request(options).then((response) => {

            // console.log(response);

            if (onSuccess != null) {
                onSuccess(response.data);
            }

            dispatch && dispatch({
                type: dispatchVariables?.SUCCESS,
                tag: tag,
                payload: response.data,
            })


        }).catch(error => {

            let errorMessage = error.message;
            if (error.response && error.response.data) {
                errorMessage = error.response.data.message;
            }
            dispatch && dispatch({
                type: dispatchVariables?.ERROR,
                payload: errorMessage,
                tag: tag,
            })
            if (onError != null) {
                onError();
            }

        })
}

export const makeJavolinPostHttpRequest = (path,data,onSuccess,onError) => {
    return makeJavolinRequest({
        method: "POST",
        path: path,
        data: data,
    },null,null,onSuccess,onError)
}

export const makeJavolinPutHttpRequest = (path,data,onSuccess,onError) => {
    return makeJavolinRequest({
        method: "PUT",
        path: path,
        data: data,
    },null,null,onSuccess,onError)
}

export const makeLinkReloadable = (link) => {
    const separator = link.includes('?') ? '&' : '?';
    return link + separator + buildQueryParamFromForm({});
}

export const makeJavolinGetHttpRequest = (path,query,onSuccess,onError) => {
    return makeJavolinRequest({
        method: "GET",
        path: path,
        query: query,
    },null,null,onSuccess,onError)
}

export const currencyFormat = (val, decimal = 2)=>{
    if (val === undefined || val === null) {
        return "";
    }
    return Intl.NumberFormat('en-us', { minimumFractionDigits: decimal,
        maximumFractionDigits: decimal, }).format(val)
}
export const generateID = () => {
    return Math.floor(Math.random() * 1000);
};

export const hasRole = (roleId) => {
    const permissions = Cookies.get("javPermissions") && JSON.parse(Cookies.get("javPermissions") );
    if (!permissions) {
        return false;
    }
    return permissions.findIndex(item => item === roleId) > 0
}

export const getTableData = (data,fields) => {
    return data && data.map((item) =>
    {
        return {
            fields: fields.map( (field,index) => {

                if (field instanceof Object) {
                    return {
                        "id": field.id,
                        "render": field.render,
                        "content": item
                    }
                }else {
                    let str = item[field]
                    if (field.indexOf(".") > -1) {
                        let ii = item;
                        field.split(".").forEach((it) => {
                            if (ii !== undefined && ii !== null) {
                                str = ii[it] && ii[it]
                                ii = str;
                            }
                        })
                    }
                    return {
                        "id": field,
                        "title": str
                    }
                }

            })
        }
    })

}

export const getParameterByName = (name, url)  => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const formatCurrency = (number) => {
    let doubleNumber = parseFloat(number || "0.00").toFixed(2);
    return parseFloat(doubleNumber).toLocaleString();
}

export const ucFirstWord = (str) => {
    return str
        .toLowerCase()
        .replaceAll("_"," ")
        .split(" ")
        .map(item => item.charAt(0).toUpperCase() + item.substring(1,item.length))
        .join(" ");
}

export const capitalize = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

function appendQueryParam(baseUrl, queryString) {
    const separator = baseUrl.includes('?') ? '&' : '?';
    return `${baseUrl}${separator}${queryString}`;
}

export const buildQueryParamFromForm = (keys) => {
    keys["instance_time"] = new Date().getTime();
    return Object.keys(keys)
        .filter((item) => {
            return keys[item] != null && keys[item] !== "";
        })
        .map((item, key) => {
            return item.trim() + "=" + keys[item];
        }).join("&");
}

export const setToInitial = (reducer,action) => {
    return async function (dispatch) {
        dispatch({ type: `INIT_${reducer.toUpperCase()}`, action });
    }
}
export const downloadFile = async (path,fileType,exportFileName) => {

    let endpointUrl = appendQueryParam(API_BASE_URL + path,`export_option=${fileType ?? 'excel'}`);
    if (path.includes("http") || path.includes("https")) {
        endpointUrl = path;
    }

    try {

        const response = await axios.get(endpointUrl, {
            responseType: 'blob',
            headers: {
                Authorization: "Bearer "+ Cookies.get("javAdminAccessToken")
            },
        })
        const contentDisposition = response.headers['content-disposition'];
        let suggestedFilename = new Date().getTime();
        if (contentDisposition) {
            const match = contentDisposition.match(/filename="(.*)"/);
            if (match && match[1]) {
                suggestedFilename = match[1];
            }
        }
        console.log("suggestedFilename",suggestedFilename);
        suggestedFilename = suggestedFilename?.toString();
        if (exportFileName) {
            suggestedFilename = exportFileName;
        }
        suggestedFilename = suggestedFilename?.replace("vnd.openxmlformats-officedocument.spreadsheetml.sheet","xlsx")

        console.log("suggestedFilename.final",suggestedFilename);
        console.log("suggestedFilename.final",exportFileName);


        console.log(response.data)
        // const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        //TODO: replace with the file extentsion
        link.setAttribute('download', suggestedFilename);
        document.body.appendChild(link);
        link.click();

        // Cleanup
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
    }
    catch (e) {
        throw e
    }
}
