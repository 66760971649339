export const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const PERMISSIONS = {
  CREATE_ADMIN_USER: "CREATE_ADMIN_USER",
  VIEW_DASHBOARD: "VIEW_DASHBOARD",
  VIEW_BUSINESSES: "VIEW_BUSINESSES",
  ADD_BUSINESS: "ADD_BUSINESS",
  VIEW_TRANSACTIONS: "VIEW_TRANSACTIONS",
  VIEW_SPOT_RATE:"VIEW_SPOT_RATE",
  VIEW_PENDING_APPROVALS: "VIEW_PENDING_APPROVALS",
  APPROVE_PENDING_APPROVALS: "APPROVE_PENDING_APPROVALS",
  VIEW_NOTIFICATIONS: "VIEW_NOTIFICATIONS",
  VIEW_FEES: "VIEW_FEES",
  VIEW_ACCOUNTS: "VIEW_ACCOUNTS",
  ADD_ROLE: "ADD_ROLE",
  VIEW_API_KEYS_AND_ACCOUNTS: "VIEW_API_KEYS_AND_ACCOUNTS",
  CREATE_API_KEYS_AND_ACCOUNTS: "CREATE_API_KEYS_AND_ACCOUNTS",
  DELETE_ADMIN_ACCOUNT: "DELETE_ADMIN_ACCOUNT",
  RESET_ADMIN_ACCOUNT: "RESET_ADMIN_ACCOUNT"
};