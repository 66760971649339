import React, {forwardRef} from 'react'
import Table from '../Table'


function DefaultTable(props,ref) {
    return (
        <div className="dark:bg-[#242A38] bg-white dark:border-gray-500 border py-2 mb-4 rounded-xl shadow-sm px-2">
            <Table {...props}
                   ref={ref}
            />
        </div>
    )
}

export default forwardRef(DefaultTable)