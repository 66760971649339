import {useDispatch, useSelector} from "react-redux";
import NotificationIcon from "../Components/Icons/NotificationIcon";
import { useNavigate } from "react-router";
import JavSelect from "../Components/Forms/JavSelect";
import { useEffect } from "react";
import {setUser} from "../../Modules/Login/duck/action"


export default function NewApplicationToolbar(props) {
    const loginState = useSelector( (state) => state.auth)


    const storedUser = localStorage.getItem("user");

    

    const userData = storedUser && JSON.parse(storedUser)



  // console.log("------Login-----",)

    const applicationRunningHeader = () => {
        return (
            <div
                className={
                    "h-28 text-white flex items-center " +
                    "w-full overflow-hidden bg-jav-primary-300 bg-bg-pattern xl:px-20 lg:px-10 md:px-5 sm:px-5 px-4"
                }
            >
                <div className="flex items-center justify-center">
                    <div
                        className="flex h-16 bg-jav-active-400 w-16 rounded-full items-center justify-center text-3xl font-satoshiBold">
                        {userData?.full_name[0]}
                    </div>
                    <div className="flex-col pl-5">
                        <div className="md:text-3xl text-xl sm:text-lg font-satoshiBold">
                            Welcome,{" "}
                            { userData?.full_name}{" "}

                        </div>
                        {/* <div className="sm:text-xl">Start building your inventory list today.</div> */}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="flex-1 overflow-hidden overflow-y-auto z-10 sticky top-0 left-0 right-0">
          <div className="h-24 xl:px-20 lg:px-10 md:px-5 sm:px-5 px-4 flex items-center justify-between shadow-sm border-b bg-white">
            <div className="h-8 w-28 hidden sm:flex md:flex xl:flex lg:flex"></div>
    
            <div className="flex items-center">
              <img src="/logo/inv_logo.png" alt="JavCommotidyLogo" className="h-28" />
            </div>
    
            <div className="sm:flex space-x-5 hidden">
             
                <div className="flex h-10 rounded-full px-2 border items-center justify-center border-jav-alert-blue-400">
                  <span className="px-3 text-xl py-2 font-satoshiBold text-jav-alert-blue-300">
                    Profile
                  </span>
                  <img
                    src="/icons/UserCircle.svg"
                    alt="User"
                    className="h-6 pr-2"
                  />
                </div>
          
              <div className="flex h-10 rounded-full border items-center justify-center py-2 border-jav-alert-blue-400 cursor-pointer">
                <span className="px-3 text-xl py-2 font-satoshiBold text-jav-alert-blue-300">
                  Notifications
                </span>
    
                <img
                  src="/icons/Bell.svg"
                  alt="Notifications"
                  className="h-6 pr-2"
                />
              </div>
            </div>
          </div>
          {applicationRunningHeader()}
        </div>
      );
}
