import {useEffect, useRef, useState} from "react";
import {useAlert} from "../../Shared/Context/AlertContext";
import {makeJavolinRequest} from "../../Shared/Utils/common";
import {CheckIcon} from "@heroicons/react/24/outline";
import CloseIcon from "../../Shared/Components/Icons/CloseIcon";
import Dialog from "../../Shared/Components/Dialog/Dialog";
import JavInput from "../../Shared/Components/Forms/JavInput";
import JavButton from "../../Shared/Components/Buttons/JavButton";

export default function EditWarehouse(props) {
    const isMobile = window.innerWidth <= 768;
    const tableRef = useRef();
    const {showAlert} = useAlert();
    const dialogWidth = isMobile ? "100%" : "40%";
    const [requestLoading, setRequestLoading] = useState(false);
    const [form, setForm] = useState({
        name: "",
        address: "",
    });

    // Initialize form state with props.details when the component mounts or props.details change
    useEffect(() => {
        if (props.details) {
            setForm({
                name: props.details.name || "",
                address: props.details.address || "",
            });
        }
    }, [props.details]);

    const handleChanges = (event) => {
        const {name, value} = event.target;
        setForm({
            ...form,
            [name]: value,
        });
    };
    const approveEditRequest = (id) => {
        setRequestLoading(true);
        makeJavolinRequest(
            {
                path: `/business/agric/warehouses`,
                method: "POST",
                data: form,
            },
            null,
            null,
            (data) => {

                props?.onCloseClicked();
                setRequestLoading(false);
                tableRef?.current?.reloadTable();
                showAlert("Request Approved Successfully", "SUCCESS", <CheckIcon className={"w-5"}/>);
                console.log("data", data);
            },
            (error) => {
                setRequestLoading(false);
                props?.onCloseClicked();
                showAlert("Error: " + error, "FAILED", <CloseIcon className="w-5"/>);
                console.log("error", error);
            }
        );
    };


    return (
        <Dialog
            position={"center"}
            style={{width: dialogWidth}}
            open={props?.open}
            onCloseClicked={props?.onCloseClicked}
            review={false}
            title={
                <>
                    <p className="mb-1">Edit Warehouse</p>
                </>
            }
        >
            <div className={"flex flex-col px-2"}>
                <div className={"flex flex-col gap-6"}>
                    <JavInput
                        disabled={false}
                        value={form.name}
                        title={"Warehouse name"}
                        name={"name"}
                        onChange={handleChanges}
                        placeholder="Eg. Javolin Anex"
                    />

                    <JavInput
                        disabled={false}
                        value={form.address}
                        title={"Address"}
                        name={"address"}
                        onChange={handleChanges}
                        placeholder="Eg. Madina Zongo junction"
                    />
                </div>
                <div className={"grid grid-cols-2 gap-2 my-3"}>
                    <div
                        className={"grid grid-cols-1 col-span-2 gap-4 py-4 "}
                    ></div>

                    <div
                        className={"grid grid-cols-2 col-span-2 gap-4 mt-8"}
                    >
                        <JavButton
                            title={"Cancel"}
                            textColor={"gray-500"}
                            bgColor={"bg-transparent"}
                            className={"border"}
                            onClick={props?.onDeclinedClick}
                        />

                        <JavButton
                            title={"Create"}
                            textColor={"text-white"}
                            bgColor={"bg-jav-primary-200"}
                            isLoading={props.isLoadingApprove}
                            onClick={() => {
                                approveEditRequest();
                            }}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
