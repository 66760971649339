import React from "react";
import Dialog from "./Dialog";
import CheckIcon from "../Icons/CheckIcon";
import JavButton from "../Buttons/JavButton";
import CloseIcon from "../Icons/CloseIcon";

function ConfirmDialog(props) {
  const isMobile = window.innerWidth <= 768;
  const dialogWidth = isMobile ? "60%" : "30%";
  return (
    <Dialog
        open={props.isOpen}
        title={props?.title ?? "Confirmation"}
        onCloseClicked={props.handleCancel}
        style={{ width:dialogWidth }}
    >
          <p className="tw-text-center">{props.message}</p>

          <div className="tw-flex tw-justify-center tw-px-3">

              {
                  !props?.onlyPositiveButton &&

                  <JavButton
                      bgColor={"tw-bg-red-600"}
                      textColor="tw-text-white"
                      className={"tw-text-white tw-px-4 tw-m-2 tw-h-9 tw-w-32"}
                      onClick={props.handleCancel}
                  >
                      {<CloseIcon className="w-5" />}
                      <span className="tw-pl-2">Cancel</span>
                  </JavButton>
              }


            <JavButton
              bgColor={""}
              textColor="tw-text-white"
              className={"tw-text-white tw-px-4 tw-m-2 tw-h-9 tw-w-32"}
              isLoading={props.requestLoading}
              onClick={props.handleConfirm}
            >
              {<CheckIcon color={"white"} height={15} width={15} />}
              <span className="tw-pl-2">Confirm</span>
            </JavButton>


      </div>
    </Dialog>
  );
}

export default ConfirmDialog;
