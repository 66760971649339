import "./App.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import AppLayout from "./Modules/Layouts/AppLayout";
import Login from "./Modules/Login";
import { useSelector } from "react-redux";
import AccountManagement from "./Modules/AccountManagement";
import InventoryDashboard from "./Modules/Dashboard";
import InventoryDetailsPage from "./Modules/Dashboard/Inventory/InventoryDetailsPage";
import RegistrationForm from "./Modules/Register";

function App() {
  const mSate = useSelector((state) => state);
  const isAuth = !!useSelector((state) => state.auth.login.token);

  return (
    <Router>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<RegistrationForm />} />

        <Route
          path="/"
          element={isAuth ? <AppLayout /> : <Navigate to={"/login"} />}
        >
          <Route path="/account-management" element={<AccountManagement />} />
          <Route path="/dashboard" element={<InventoryDashboard />} />
          <Route path="/inventory/:id" element={<InventoryDetailsPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
