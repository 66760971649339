import { useEffect, useRef, useState } from "react";
import useOnClickOutside from "use-onclickoutside";
import ArrowDownIcon from "../Icons/Arrow/ArrowDownIcon";

export default function JavSelect(props) {
  const [opened, setOpened] = useState(false);
  const [value, setValue] = useState(null);

  const getPosition = (position) => {
    switch (position) {
      case "bottom":
        return "left-0 right-0";
      default:
        return "-top-52 left-0 right-0";
    }
  };
  const ref = useRef(null);
  useOnClickOutside(ref, (e) => {
    if (e.target) {
      if (e.target.dataset.class !== "allowed") {
        if (opened) {
          setOpened(false);
        }
      }
    }
  });

  useEffect(() => {
    if (props.items && props.items.length > 0) {
      let initialValue = props.value || props.items[0];
      if (typeof initialValue === "object") {
        initialValue = initialValue.name || initialValue.title || "";
      }
      setValue(initialValue);
    }
  }, [props.items, props.value]);

  const handleItemClick = (item) => {
    let selectedValue = item;
    if (typeof selectedValue === "object") {
      selectedValue = selectedValue.name || selectedValue.title || "";
    }

    setValue(selectedValue);
    setOpened(false);

    // Notify parent component of the selection change
    if (props.onChange) {
      props.onChange(item);
    }
  };

  return (
    <div
      className={`flex bg-white dark:bg-[#242A38] h-12 items-center text-gray-600 ${props.bgColor}`}
    >
      {props.title && (
        <span className={"text-xs pt-0 mx-1"}>{props.title}</span>
      )}
      <div
        className={`relative ${
          props.hideBorder ? "" : "border dark:border-gray-500"
        } w-full rounded px-1 py-2.5`}
      >
        <div className={"flex mx-1"}>
          <input
            data-class={"allowed"}
            ref={ref}
            className={
              "outline-none cursor-pointer w-full text-lg bg-white dark:bg-[#242A38] dark:text-gray-100"
            }
            onFocus={() => {
              setOpened(true);
            }}
            autoComplete={Math.random()}
            value={value ?? props.value}
          />
          {!props.hideArrowIcon && (
            <svg
              className="flex-shrink-0 w-4 h-4 mx-1"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M6 9l6 6 6-6" />
            </svg>
          )}
        </div>

        <div
          className={`mt-4 absolute z-50 border 
                            overflow-hidden overflow-y-auto
                            rounded-lg max-h-44 bg-white 
                            mb-2
                           ${getPosition(props.position)}
                         ${opened ? "block" : "hidden"}
                         `}
        >
          {props?.items?.map((item, index) => {
            let text = item;
            if (item instanceof Object) {
              text = item.name || item.title;
            }
            // console.log("..........",text,".........", value)
            return (
              <div
                key={index}
                data-class={"allowed"}
                onClick={() => handleItemClick(item)}
                className="h-10 px-6 border-t flex justify-between text-gray-600
                            hover:bg-gray-50 text-xs cursor-pointer
                             items-center"
              >
                {text}

                {text === value && (
                  <div className="h-2 bg-yellow-400 w-2 rounded border border-yellow-500" />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
