import React, { useEffect } from "react";
import JavButton from "../Buttons/JavButton";
import CloseIcon from "../Icons/CloseIcon";

export default function Dialog({
  icon: Icon,
  open,
  position = "center",
  style,
  width = "w-96",
  hasBorder,
  titleWithSubtitle,
  title,
  subTitle,
  onCloseClicked,
  review,
  actions = [],
  children,
  no_title
}) {
  useEffect(() => {
    if (open) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [open]);

  if (!open) {
    return null;
  }

  const getPosition = (position) => {
    switch (position) {
      case "top":
        return "justify-start";
      case "bottom":
        return "justify-end";
      case "center":
      default:
        return "justify-center";
    }
  };

  const handleBackdropClick = (event) => {
    if (event.target.classList.contains("dialog-backdrop")) {
      onCloseClicked();
    }
  };

  return (
    <div
      className={`fixed inset-0 z-50 flex ${getPosition(
        position
      )} items-center p-2 backdrop-filter backdrop-blur-sm dialog-backdrop`}
      onClick={handleBackdropClick}
    >
      <div
        className="fixed inset-0 bg-black opacity-60"
      ></div>

      <div
        style={style}
        className={`${width} ${hasBorder ? "border" : ""} 
          z-60 overflow-auto max-h-full bg-white rounded-xl relative`}
      >

        {/* Title */}
        {
        // (
        //   <div className="bg-jav-card-100 bg-bg-pattern relative py-8 flex items-center justify-center">
        //     <div className="flex flex-col text-center">
        //       <span className="font-firmaBold text-lg text-black">
        //         {title}
        //       </span>
        //       <span className="font-satoshiMedium">{subTitle}</span>
        //     </div>
        //     <CloseIcon
        //       onClick={onCloseClicked}
        //       className="stroke-current shadow-lg text-black stroke-2 absolute top-3 right-5 bg-white rounded-full cursor-pointer"
        //     />
        //   </div>
        // ) : 
        !no_title && !titleWithSubtitle && (
          <div
            className={`border-b ${
              review ? "bg-bg-review" : "bg-jav-primary-300"
            } text-white px-4 py-3 flex items-center justify-between`}
          >
            <h3 className="text-sm font-satoshiBold">{title}</h3>

            <CloseIcon
              onClick={onCloseClicked}
              className={`stroke-current shadow-lg text-black stroke-2 ${
                review ? "bg-white" : "bg-blue-50"
              } rounded-full cursor-pointer`}
            />
          </div>
        )}

        <div className="py-3 px-3">{children}</div>

        <div
          className={`grid px-5 gap-x-4 grid-cols-${Math.min(
            actions.length,
            3
          )} pb-2`}
        >
          {actions.map((action, index) => (
            <JavButton
              key={index}
              onClick={action.onClick}
              isLoading={action.loading}
              className={`text-white ${action.className}`}
              textColor="text-white"
              title={action.title}
              bgColor={action.bgColor}
            />
          ))}
        </div>
      </div>
    </div>
  );
}