import JavInput from "../../Shared/Components/Forms/JavInput";
import JavButton from "../../Shared/Components/Buttons/JavButton";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {login} from "./duck/action";
import {useNavigate} from "react-router-dom";
import {useAlert} from "../../Shared/Context/AlertContext";

export default function Login() {

    const loginState = useSelector( (state) => state.auth)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showAlert } = useAlert();

    const userLoginSate = useSelector((state) => state.login);

    const [form, setForm] = useState({
        email: "",
        password: "",
    });

    const handleChanges = (event) => {
        const value = event.target.value;
        setForm({
            ...form,
            [event.target.name]: value,
        });
    };

    const onLoginClicked = () => {
        dispatch(login(form?.email,form?.password));
    }

    useEffect(() => {

        if (loginState.login.errorMessage) {
            showAlert(loginState?.login.errorMessage,"red")
        }

        if (loginState.login.loginSuccess) {
            navigate("/dashboard")
        }

    },[loginState?.login])



    return (
        <div
            className="flex bg-white overflow-hidden min-h-screen min-w-full  w-full md:w-3/4 lg:w-2/3 xl:w-2/3">
            <div className="w-full md:w-1/2 p-8 lg:px-36 md:px-20 min-h-screen content-center">
                <div className="flex flex-col justify-center items-center">
                    <img
                        src="/logo/inv_logo.png"
                        alt="Logo"
                        className="w-56 h-360 mb-4"
                    />
                    <h2 className="text-5xl font-bold mb-2">Welcome back</h2>
                    <p className="text-gray-600 mb-4 text-xl">
                        Sign in to start using our Inventory System
                    </p>
                </div>
                <div className="mt-8 grid gap-2 grid-cols-1">
                    <JavInput
                        title={"Email"}
                        name={"email"}
                        placeholder={"Enter Email"}
                        value={form.email}
                        onChange={handleChanges}
                    />
                    <div className="mb-4"/>
                    <JavInput
                        title={"Password"}
                        name={"password"}
                        type={"password"}
                        placeholder={"Enter password"}
                        onChange={handleChanges}
                        value={form.password}
                    />

                    <div className="mb-4 text-right">
                        <a
                            className="text-blue-500 text-xl font-semibold"
                            href="/forgot-password"
                        >
                            Forgot password?
                        </a>
                    </div>
                    <div className={"flex mt-1"}>
                        <JavButton
                            title={"Continue"}
                            textColor={"text-white"}
                            className={"w-full h-14 text-2xl"}
                            isLoading={userLoginSate?.loading}
                            onClick={onLoginClicked}
                        />
                    </div>
                </div>
                <p className="text-center text-xl font-satoshiBold text-gray-600 pt-5">
                    Don't have an account?{" "}
                    <a
                        className="text-jav-card-500 font-semibold"
                        href="/register"
                    >
                        Create account
                    </a>
                </p>
            </div>

            <div
                className="hidden md:block md:w-2/3 relative bg-cover bg-center p-4 hide-scrollbar">
                <div
                    className="h-full rounded-lg overflow-hidden m-10 bg-cover bg-center bg-no-repeat absolute inset-0"
                    style={{backgroundImage: "url('/images/bg/plain_bg.png')"}}
                >
                    <div className="h-full p-2.5"></div>
                </div>
                <div
                    className="h-full rounded-lg overflow-hidden bg-contain bg-center bg-no-repeat absolute inset-0"
                    style={{backgroundImage: "url('/images/bg/map.png')"}}
                ></div>
            </div>
        </div>
    );
}