import ExcelIcon from "../Icons/ExcelIcon";
import CSVIcon from "../Icons/CSVIcon";
import PDFIcon from "../Icons/PDFIcon";
import JavSelect from "../Forms/JavSelect";
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getDataForTable} from "./duck/action";
import ListIcon from "../Icons/ListIcon";
// import {LoadingIcon} from "../JavIcons";
import * as PropTypes from "prop-types";
// import {downloadFile, getParameterByName, getTableData, makeLinkReloadable} from "../../../helpers/utils";
import { downloadFile, getParameterByName, getTableData, makeLinkReloadable } from "../../Utils/common";
import LoadingIcon from "../Icons/LoadingIcon";
import JavButton from "../Buttons/JavButton";


const renderOptions = [{ name: "List", icon: ListIcon }, { name: "Chart", icon: PDFIcon } ];

function JavTabSwitch(props) {
    return null;
}

JavTabSwitch.propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.any
};

function Table(props,ref) {

    const dispatch = useDispatch();
    const tableState = useSelector( (state) => state.table)
    const [tableData,setTableData] = useState([]);
    const [exportDownloading,setExportDownloading] = useState(false);
    const [renderOption,setRenderOption] = useState(renderOptions?.[0]);
    const [links,setLinks] = useState([]);
    const [config,setConfig] = useState({
        pageSize: props.pageNumbers ? props.pageNumbers[0] : 10,
        page: 1,
        link: props.link,
        pageNumbers: props.pageNumbers ? props.pageNumbers : [10, 25, 50,100, "All"]
    })
    const [tableSummary,setTableSummary] = useState("...");


    useImperativeHandle(ref,() => {
        return {
            isLoading: () => {
                return tableState.loading
            },
            reloadTable: (reloadLink) => {
                console.log("am here _"+reloadLink)
                if (!reloadLink) {
                    console.log("am here")
                    setConfig({
                        ...config,
                        link: makeLinkReloadable(config?.link)
                    })
                }
                else if (reloadLink && config) {
                    setConfig({
                        ...config,
                        link: reloadLink
                    });
                }
            },
        }
    },[tableState])



    useEffect(() => {
        if (config.link) {
            dispatch(getDataForTable({...config},props.tag))
        }
    },[config,props.currentVersion])

    useEffect(() => {
        if (props.fields) {
            if (tableState[props.tag]?.data) {
                setTableData(getTableData(tableState[props.tag].data,props.fields));
                setLinks(tableState[props.tag]?.data?.links)
                setTableSummary(`${tableState[props.tag]?.data?.from || '0'} - ${tableState[props.tag]?.data?.to || '0'} of ${tableState[props.tag]?.data?.total}`)
                if (props.onLoadingDone) {
                    props.onLoadingDone();
                }
            }
        }
    },[tableState,props.dependencies])

    useEffect(() => {

    },[props.dependencies]);


    const onExportClicked = (fileType) => {
        if (!props.link) {
            alert(`Issue downloading ${fileType} content`);
            return;
        }
        setExportDownloading(fileType);
        downloadFile(props?.link,fileType).then(r => {
            setExportDownloading(null);
        })
    }

    // console.log("=================",(tableState[props.tag]?.data))

    return (
        <div className={"rounded-md mx-2 font-satoshiMedium my-2 overflow-scroll  relative"}>

            {
                !props.clientSide &&
                <div className="flex flex-row items-center justify-between">

                    <span className={"font-satoshiBold text-lg"}>
                        {props?.title}
                        {props?.renderTitle && props?.renderTitle()}
                    </span>

                    <div className={"flex items-center"}>

                        {/* {
                            props?.showFilter &&
                            // <JavFilter/>
                        } */}

                        <JavButton onClick={() => onExportClicked('excel')}
                                   bgColor={"dark:bg-[#242A38]"}
                                   textColor={"text-gray-600"}
                                   className={"h-8 border border-gray-300 dark:border-gray-500"}
                                   title={"excel"}>
                            <ExcelIcon className={"fill-current text-green-800"}/>
                            <LoadingIcon className={`animate-spin h-5 w-5 mx-2
                                            fill-gray-600 ${exportDownloading === 'excel' ? 'block' : 'hidden'}`}/>
                        </JavButton>

                        <JavButton onClick={() => onExportClicked('csv')}
                                   bgColor={"dark:bg-[#242A38]"}
                                   textColor={"text-gray-600"}
                                   className={"h-8 border mx-2 border-gray-300 dark:border-gray-500"}
                                   title={"csv"}>
                            <CSVIcon className={"w-4 mx-2 h-4 fill-current text-blue-600"} />
                            <LoadingIcon className={`animate-spin h-5 w-5 mx-2
                                            fill-gray-600 ${exportDownloading === 'csv' ? 'block' : 'hidden'}`}/>
                        </JavButton>

                        <JavButton onClick={() => onExportClicked('pdf')}
                                   bgColor={"dark:bg-[#242A38]"}
                                   textColor={"text-gray-600"}
                                   className={"h-8 border border-gray-300 dark:border-gray-500"}
                                   title={"pdf"}>
                            <PDFIcon className={"w-4 mx-2 h-4 fill-current text-red-600"}/>
                            <LoadingIcon className={`animate-spin  h-5 w-5 mx-2
                                            fill-gray-600 ${exportDownloading === 'pdf' ? 'block' : 'hidden'}`}/>
                        </JavButton>

                            {
                                props?.rightActions &&
                                <div className={"ml-3"}>
                                    <div className={"flex-1 ml-1 flex items-center justify-end"}>
                                        {
                                            props?.rightActions?.map(item => (
                                                item?.show &&
                                                <JavButton {...item} />
                                            ))
                                        }
                                    </div>
                                </div>

                            }




                    </div>

                </div>

            }

            {
                renderOption?.name.toLowerCase() === "chart" &&
                props?.chartsContent
            }

            {
                renderOption?.name.toLowerCase() === "list" &&
                <table className={`w-full my-4 text-xs table-auto overflow-scroll `}>
                    <thead>
                    <tr className="border dark:border-gray-600 dark:bg-[#242A38]
                               text-left dark:text-white h-12 rounded ">
                        {props.columns && props.columns.map( (column,index) => {
                            return (
                                <th key={index} className={"text-center font-satoshi uppercase"}>
                                    { column}
                                </th>
                            )
                        })}
                    </tr>
                    </thead>

                    <tbody className={"dark:bg-[#242A38] text-gray-700 dark:text-gray-200"}>
                    {
                        props.data && props.data.map((data,index) => {
                            return (
                                <tr key={index} className="border py-2 dark:border-gray-600 text-left w-full rounded ">
                                    {
                                        data.fields && data.fields.map((field,fieldIndex) => {
                                            if (field.render) {
                                                return field.render(field.content);
                                            }
                                            return (
                                                <td key={fieldIndex} className={"text-center py-3.5"}>
                                                    {field.title}
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                    {
                        props.data && props.data.length === 0 &&
                        <tr className={"border"}>
                            <td colSpan={props.columns.length} className={"text-center py-4"}>
                                No Data
                            </td>
                        </tr>
                    }

                    {
                        tableData && tableData.map((data,index) => {
                            return (
                                <tr key={`${props?.tag}__${index}`} className="border text-sm font-satoshiMedium dark:border-gray-600 text-left h-12 rounded ">
                                    {
                                        data.fields && data.fields.map((field,index2) => {
                                            if (field.render) {
                                                return field.render(field.content,index);
                                            }
                                            return (
                                                <td key={index2} className={"text-center"}>
                                                    {field.title}
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }

                    {/*Show Loading indicator for Table*/}
                    {
                        tableState[props?.tag]?.loading &&
                        <tr className={"h-12 absolute top-1/2 left-1/2"}>
                            <td colSpan={props.columns.length} className={"w-full"}>
                                <div className={"w-full flex flex-col my-6 items-center justify-center"}>
                                    <LoadingIcon
                                        className={`animate-spin h-8 w-8 mx-2
                                            fill-gray-600`}
                                    />
                                    Loading...
                                </div>

                            </td>

                        </tr>
                    }

                    {/*No Content */}
                    {
                        tableState[props?.tag]?.data?.length === 0 &&
                        <tr className={"border"}>
                            <td colSpan={props.columns.length} className={"text-center py-4"}>
                                No Data
                            </td>
                        </tr>
                    }


                    {
                        // tableData && tableData.length === 0 &&
                        // <tr key={-1} className="border border dark:border-gray-600 text-left h-12 rounded ">
                        //     <td colSpan={props.columns.length} className={""}>
                        //         <div className={"flex text-gray-500 items-center justify-center"}>
                        //
                        //         </div>
                        //     </td>
                        // </tr>
                    }
                    </tbody>
                </table>

            }

            {
                (!props?.hideBottomToolbar && !props.clientSide && renderOption?.name.toLowerCase() === "list" ) &&
                <div className={"flex pb-10 items-center justify-between"}>

                    <div className="pl-1">
                        <JavSelect items={config.pageNumbers}
                                   title={"Rows per page"}
                                   position={"ada"}
                                   onChange={(item) => {
                                       setConfig({
                                           ...config,
                                           pageSize: item
                                       })
                                   }}
                        />
                    </div>

                    <div className={"flex"}>
                        {
                            links && links.map((link,index) => {
                                return (
                                    <a key={index} onClick={() => {
                                        if (link.url) {
                                            let page = getParameterByName("page",link.url);
                                            setConfig({
                                                ...config,
                                                page: parseInt(page)
                                            })
                                        }
                                    }} className={`h-8 w-8 ${!link.active ? 'text-blue-900 border  rounded-full': 'text-blue-400 rounded-full border bg-blue-50'} font-satoshiBold
                                                cursor-pointer text-xs flex mx-1 items-center
                                                 justify-center  `}>
                                        {
                                            link.label
                                                .replaceAll("&raquo;","")
                                                .replaceAll("Previous","<")
                                                .replaceAll("Next",">")
                                                .replaceAll("&laquo;","")
                                        }
                                    </a>
                                )
                            })
                        }

                    </div>


                    <div className={"pr-10 text-gray-600 text-xs flex items-center"}>
                        <span>{tableSummary}</span>
                    </div>

                </div>

            }


        </div>
    )
}



export default forwardRef(Table)