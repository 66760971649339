import { ActionTypes } from "./type";
import Cookies from "js-cookie";

const initialState = {
  login: {
    errorMessage: null,
    loginSuccess: false,
    isLoading: false,
    token: Cookies.get("javAdminAccessToken"),
    user:
      Cookies.get("javAdmins") && JSON.parse(Cookies.get("javAdmins")),
    permissions:
      Cookies.get("javPermissions") &&
      JSON.parse(Cookies.get("javPermissions")),
  },
  forgotPassword: {
    success: false,
    loading: false,
    error: null,
    data: null,
  },
  setPassword: {
    success: false,
    loading: false,
    error: null,
    data: null,
  },
};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_LOGIN:
      return {
        ...state,
        login: {
          ...state.login,
          isLoading: true,
          errorMessage: null,
          loginSuccess: false,
        },
      };
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          ...state.login,
          isLoading: false,
          loginSuccess: true,
          errorMessage: null,
          user: action.payload.user,
          token: action.payload.token.access_token,
          permissions: action.payload.permissions,
        },
      };
    case ActionTypes.LOGIN_ERROR:
      return {
        ...state,
        login: {
          ...state.login,
          isLoading: false,
          loginSuccess: false,
          errorMessage: action.payload,
        },
      };
    case ActionTypes.LOGOUT:
      return {
        ...state,
        login: {
          errorMessage: null,
          loginSuccess: false,
          isLoading: false,
          token: null,
          user: null,
          permissions: [],
        },
      };

    // forgot_password
    case ActionTypes.REQUEST_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          loading: true,
          success: false,
          error: null,
        },
      };
    case ActionTypes.SUCCESS_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          success: true,
          loading: false,
          data: action.payload,
        },
      };
    case ActionTypes.ERROR_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          success: false,
          loading: false,
          error: action.payload,
        },
      };

    // set_password
    case ActionTypes.REQUEST_SET_PASSWORD:
      return {
        ...state,
        setPassword: {
          ...state.setPassword,
          loading: true,
          success: false,
          error: null,
        },
      };
    case ActionTypes.SUCCESS_SET_PASSWORD:
      return {
        ...state,
        setPassword: {
          ...state.setPassword,
          success: true,
          loading: false,
          data: action.payload,
        },
      };
    case ActionTypes.ERROR_SET_PASSWORD:
      return {
        ...state,
        setPassword: {
          ...state.setPassword,
          success: false,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};
