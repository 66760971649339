import {ActionTypes} from "./type";
const initialState = {
    roles: {
        fetch: {
            success: false,
            loading: false,
            error: null,
            data: []
        },
        create: {
            success: false,
            loading: false,
            error: null,
            data: []
        },
    },
    permissions: {
        fetch: {
            success: false,
            loading: false,
            error: null,
            data: []
        }
    },
    accounts: {
        fetch: {
            success: false,
            loading: false,
            error: null,
            data: []
        },
        create: {
            success: false,
            loading: false,
            error: null,
            data: []
        },
        edit: {
            success: false,
            loading: false,
            error: null,
            data: []
        },
        delete: {
            success: false,
            loading: false,
            error: null,
            data: []
        },
        reset: {
            success: false,
            loading: false,
            error: null,
            data: []
        }
    }
}

    export const AccountReducer = (state = initialState,action) => {
    switch (action.type) {

        //fetch roles
        case ActionTypes.REQUEST_FETCH_ROLES:
            return {
                ...state,
                roles: {
                    ...state.roles,
                    fetch: {
                        ...state.roles.fetch,
                        loading: true,
                        success: false,
                        error: null
                    },
                    create: initialState.roles.create,
                }
            }
        case ActionTypes.FETCH_SUCCESS_ROLES:
            return {
                ...state,
                roles: {
                    ...state.roles,
                    fetch: {
                        ...state.roles.fetch,
                        success: true,
                        loading: false,
                        data: action.payload
                    }
                }
            }
        case ActionTypes.FETCH_ERROR_ROLES:
            return {
                ...state,
                roles: {
                    ...state.roles,
                    fetch: {
                        ...state.roles.fetch,
                        success: false,
                        loading: false,
                        error: action.payload
                    }
                }
            }

        //create roles
        case ActionTypes.REQUEST_CREATE_ROLES:
            return {
                ...state,
                roles: {
                    ...state.roles,
                    create: {
                        ...state.roles.create,
                        loading: true,
                        success: false,
                        error: null
                    }
                }
            }
        case ActionTypes.CREATE_SUCCESS_ROLES:
            return {
                ...state,
                roles: {
                    ...state.roles,
                    create: {
                        ...state.roles.create,
                        success: true,
                        loading: false,
                        data: action.payload
                    }
                }
            }
        case ActionTypes.CREATE_ERROR_ROLES:
            return {
                ...state,
                roles: {
                    ...state.roles,
                    create: {
                        ...state.roles.create,
                        success: false,
                        loading: false,
                        error: action.payload
                    }
                }
            }



        //fetch roles
        case ActionTypes.REQUEST_FETCH_PERMISSIONS:
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    fetch: {
                        ...state.permissions.fetch,
                        loading: true,
                        success: false,
                        error: null
                    }
                }
            }
        case ActionTypes.FETCH_SUCCESS_PERMISSIONS:
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    fetch: {
                        ...state.permissions.fetch,
                        success: true,
                        loading: false,
                        data: action.payload
                    }
                }
            }
        case ActionTypes.FETCH_ERROR_PERMISSIONS:
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    fetch: {
                        ...state.permissions.fetch,
                        success: false,
                        loading: false,
                        error: action.payload
                    }
                }
            }


        //fetch accounts
        case ActionTypes.REQUEST_FETCH_ACCOUNT:
            return {
                ...state,
                accounts: {
                    ...state.accounts,
                    fetch: {
                        ...state.accounts.fetch,
                        loading: true,
                        success: false,
                        error: null
                    },
                    create: {
                        ...state.accounts.create,
                        ...initialState.accounts.create
                    },
                    delete: {
                        ...state.delete.create,
                        ...initialState.delete.create
                    }
                }
            }
        case ActionTypes.FETCH_SUCCESS_ACCOUNT:
            return {
                ...state,
                accounts: {
                    ...state.accounts,
                    fetch: {
                        ...state.accounts.fetch,
                        success: true,
                        loading: false,
                        data: action.payload
                    }
                }
            }
        case ActionTypes.FETCH_ERROR_ACCOUNT:
            return {
                ...state,
                accounts: {
                    ...state.accounts,
                    fetch: {
                        ...state.accounts.fetch,
                        success: false,
                        loading: false,
                        error: action.payload
                    }
                }
            }


        //create accounts
        case ActionTypes.REQUEST_CREATE_ACCOUNT:
            return {
                ...state,
                accounts: {
                    ...state.accounts,
                    create: {
                        ...state.accounts.create,
                        loading: true,
                        success: false,
                        error: null
                    }
                }
            }
        case ActionTypes.CREATE_SUCCESS_ACCOUNT:
            return {
                ...state,
                accounts: {
                    ...state.accounts,
                    create: {
                        ...state.accounts.create,
                        success: true,
                        loading: false,
                        data: action.payload
                    }
                }
            }
        case ActionTypes.CREATE_ERROR_ACCOUNT:
            return {
                ...state,
                accounts: {
                    ...state.accounts,
                    create: {
                        ...state.accounts.create,
                        success: false,
                        loading: false,
                        error: action.payload
                    }
                }
            }


        //delete accounts
        case ActionTypes.REQUEST_DELETE_ACCOUNT:
            return {
                ...state,
                accounts: {
                    ...state.accounts,
                    delete: {
                        ...state.accounts.delete,
                        loading: true,
                        success: false,
                        error: null
                    }
                }
            }
        case ActionTypes.DELETE_SUCCESS_ACCOUNT:
            return {
                ...state,
                accounts: {
                    ...state.accounts,
                    delete: {
                        ...state.accounts.delete,
                        success: true,
                        loading: false,
                        data: action.payload
                    }
                }
            }
        case ActionTypes.DELETE_ERROR_ACCOUNT:
            return {
                ...state,
                accounts: {
                    ...state.accounts,
                    delete: {
                        ...state.accounts.delete,
                        success: false,
                        loading: false,
                        error: action.payload
                    }
                }
            }

        //edit accounts
        case ActionTypes.REQUEST_EDIT_ACCOUNT:
            return {
                ...state,
                accounts: {
                    ...state.accounts,
                    edit: {
                        ...state.accounts.edit,
                        loading: true,
                        success: false,
                        error: null
                    }
                }
            }
        case ActionTypes.CREATE_SUCCESS_EDIT:
            return {
                ...state,
                accounts: {
                    ...state.accounts,
                    edit: {
                        ...state.accounts.edit,
                        success: true,
                        loading: false,
                        data: action.payload
                    }
                }
            }
        case ActionTypes.EDIT_ERROR_ACCOUNT:
            return {
                ...state,
                accounts: {
                    ...state.accounts,
                    edit: {
                        ...state.accounts.edit,
                        success: false,
                        loading: false,
                        error: action.payload
                    }
                }
            }

        //reset accounts
        case ActionTypes.REQUEST_RESET_ACCOUNT:
            return {
                ...state,
                accounts: {
                    ...state.accounts,
                    reset: {
                        ...state.accounts.reset,
                        loading: true,
                        success: false,
                        error: null
                    }
                }
            }
        case ActionTypes.RESET_SUCCESS_ACCOUNT:
            return {
                ...state,
                accounts: {
                    ...state.accounts,
                    reset: {
                        ...state.accounts.reset,
                        success: true,
                        loading: false,
                        data: action.payload
                    }
                }
            }
        case ActionTypes.RESET_ERROR_ACCOUNT:
            return {
                ...state,
                accounts: {
                    ...state.accounts,
                    reset: {
                        ...state.accounts.reset,
                        success: false,
                        loading: false,
                        error: action.payload
                    }
                }
            }



        default:
            return state

    }
}

