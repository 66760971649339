import * as React from "react"

const EditIcon = (props) => (
    <svg
        width={16}
        height={16}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M8.84 2.4 3.367 8.193c-.207.22-.407.653-.447.953l-.247 2.16c-.086.78.474 1.314 1.247 1.18l2.147-.366c.3-.053.72-.273.926-.5l5.474-5.794c.946-1 1.373-2.14-.1-3.533C10.9.913 9.787 1.4 8.84 2.4Z"
            strokeWidth={1.5}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.927 3.367A4.084 4.084 0 0 0 11.56 6.8M2 14.666h12"
            strokeWidth={1.5}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default EditIcon


export const Edit = ( {width, height, color, className}) => {
    return (
      <svg width={width} height={height} className={className} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_387_25198)">
          <path d="M2.44733 15.2501H5.27566L15.6467 4.87911L12.818 2.05078L2.44733 12.4218V15.2501Z" stroke={color ? color:"currentColor"} strokeWidth="1.5" strokeLinejoin="round" />
          <path d="M9.98834 4.87891L12.8167 7.70724" stroke={color ? color:"currentColor"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </g>    
      </svg>
  
    )
  }