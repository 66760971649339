import JavSelect from "./JavSelect";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCodes } from "./duck/action";
import { makeJavolinRequest } from "../../Utils/common";

export default function JavFormSelect(props) {
    const dispatch = useDispatch();
    const formsState = useSelector((state) => state.forms);
    const [remoteItems, setRemoteItems] = useState([]);
    const [value, setValue] = useState(props?.value);

    useEffect(() => {
        if (formsState.fetch_codes[props.code]) {
            let items = formsState.fetch_codes[props.code].map((item) => {
                return {
                    ...item,
                    title: item[props?.codeField],
                };
            });

            // If showFirstOnly is true, set remoteItems to only the first item
            if (props.showFirstOnly) {
                items = items.slice(1, 2);
            }

            setRemoteItems(items);

            if (props.selected) {
                let selectedItem = formsState.fetch_codes[props?.code]?.filter(
                    (item) => props?.selected.toString() === item.id.toString()
                )[0];
                setValue(selectedItem?.[props?.codeField]);
            }
        }
    }, [formsState]);

    const fetchCommonCodes = (code) => {
        switch (code) {
            case "users-auto-search":
                console.log("fetchCommonCodeViaEndpoint");
                fetchCommonCodeViaEndpoint("users-auto-search", "");
                break;
            default:
                dispatch(fetchCodes(code, props?.codeOption));
        }
    };

    const fetchCommonCodeViaEndpoint = (endpoint, search) => {
        makeJavolinRequest(
            {
                path: endpoint,
                method: "GET",
                query: { search },
            },
            null,
            null,
            (data) => {
                console.log("data", data);
                let items = data.map((item) => {
                    return {
                        ...item,
                        title: item[props?.codeField],
                    };
                });

                // If showFirstOnly is true, set remoteItems to only the first item
                if (props.showFirstOnly) {
                    items = items.slice(0, 1);
                }

                setRemoteItems(items);
            },
            (error) => {
                console.log("error is ", error);
            }
        );
    };

    useEffect(() => {
        if (props?.code) {
            fetchCommonCodes(props?.code);
        }
    }, [props?.code]);

    return (
        <div
            className={`flex 
                    ${props.isColumn ? "flex-row" : "flex-col"} 
                    ${props.className}
                    `}
        >
            <h3
                className={`text-xl text-black
                    ${props.isColumn ? "w-2/5 bg-gray-100 border border-gray-200 dark:bg-[#242A38] flex items-center px-2" : ""}
                    `}
            >
                {props.title}
            </h3>

            <div
                className={`${props.isColumn ? "w-3/5" : ""}
                            ${props.isColumn ? "" : "rounded-md"}
                            ${!props.isColumn ? "" : "border border-gray-200 dark:border-gray-500"}
                            `}
            >
                <JavSelect
                    selected={props.selected}
                    onChange={props.onChange}
                    bgColor={props.bgColor}
                    items={props.code ? remoteItems : props.items}
                    position={props.position}
                    hideBorder={props.isColumn ? true : props.hideBorder}
                    value={value}
                />
            </div>
        </div>
    );
}
