// AlertContext.js
import React, { createContext, useContext, useState } from 'react';

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
    const [alertContent, setAlertVisible] = useState(null);
    const [alertColor,setAlertColor] = useState("blue");

    const showAlert = (message,color,closure) => {
        setAlertVisible(message);
        setAlertColor(color)
        setTimeout(() => {
            hideAlert();
            if (closure) {
                closure();
            }
        },2500)
    }
    const hideAlert = () => setAlertVisible(null);

    return (
        <AlertContext.Provider value={{ alertContent, alertColor , showAlert, hideAlert }}>
            {children}
        </AlertContext.Provider>
    );
};
