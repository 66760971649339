import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from "../../Shared/Components/Table";
import CreateRole from "./createRole";
import JavButton from "../../Shared/Components/Buttons/JavButton";
import { deleteAccount, deleteRole, resetAccount } from "./duck/action";
import CreateAccount from "./createUser";
import DeleteIcon from "../../Shared/Components/Icons/DeleteIcon";
import YesNoDialog from "../../Shared/Components/Dialog/YesNoDialog";
import EditIcon from "../../Shared/Components/Icons/EditIcon";
import ResetIcon from "../../Shared/Components/Icons/ResetIcon";
import { buildQueryParamFromForm } from "../../Shared/Utils/common";




export default function AccountManagement() {
    const dispatch = useDispatch();
    const [showCreateRoleDialog, setShowCreateRoleDialog] = useState(false);
    const [showCreateAccountDialog, setShowCreateAccountDialog] = useState(false);
    const accountState = useSelector((state) => state.account);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);
    const userAccountsTableRef = useRef();
    const rolesTableRef = useRef();
    const link = 'admin-portal/admin/accounts';
    const rolesLink = 'admin-portal/roles';
    const isDeleting = accountState.accounts.delete.loading;
    const isResetting = accountState.accounts.reset.loading;
    const yesButtonLoading = selectedAction === "delete" ? isDeleting : isResetting;

    useEffect(() => {
        console.log("getPermissions", accountState);
    }, [accountState]);


    useEffect(() => {
        if (accountState.roles.create.success) {
            setShowCreateRoleDialog(false);
            if (rolesTableRef.current && rolesTableRef.current.reloadTable) {
                rolesTableRef.current.reloadTable(`${rolesLink}?${buildQueryParamFromForm({})}`); 
            }
        }
    }, [accountState.roles.create.success]);

    useEffect(() => {
        if (accountState.accounts.create.success || accountState.accounts.delete.success) {
            setShowCreateAccountDialog(false);
            setSelectedItem(null);
            setSelectedType(null);
            setSelectedAction(null);
            userAccountsTableRef.current.reloadTable();
        }
    }, [accountState.accounts.create.success, accountState.accounts.delete.success]);

    
    useEffect(() => {
        if (accountState.accounts.create.success) {
            setShowCreateAccountDialog(false);
            userAccountsTableRef.current.reloadTable(`${link}?${buildQueryParamFromForm({})}`);
        }
    }, [accountState.accounts.create.success]);

    useEffect(() => {
        if (accountState.accounts.delete.success) {
            userAccountsTableRef.current.reloadTable(`${link}?${buildQueryParamFromForm({})}`);
        }
    }, [accountState.accounts.delete.success]);



    return (
        <div>
            <CreateRole
                open={showCreateRoleDialog}
                onCloseClicked={() => setShowCreateRoleDialog(false)}
            />

            <CreateAccount
                open={showCreateAccountDialog}
                user={selectedItem}
                title={selectedItem ? "Edit Admin Account" : "Create Admin Account"}
                onCloseClicked={() => setShowCreateAccountDialog(false)}
            />

            <YesNoDialog
                open={selectedItem != null && (selectedAction === "delete" || selectedAction === "reset")}
                title={"Are you sure?"}
                yesLoading={yesButtonLoading}
                onYesClicked={() => {
                    switch (selectedType) {
                        case "user":
                            if (selectedAction === "delete") {
                                dispatch(deleteAccount(selectedItem.id));
                            } else if (selectedAction === "reset") {
                                dispatch(resetAccount(selectedItem.id));
                            }
                            break;
                        case "role":
                            dispatch(deleteRole());
                            break;
                        default:
                            break;
                    }
                }}
                onNoTapped={() => setSelectedItem(null)}
                onCloseClicked={() => setSelectedItem(null)}
            >
                <div className="text-xs py-3">
                    {selectedType === "user" && selectedAction === "delete" && <span>Are you sure you want to delete this account?</span>}
                    {selectedType === "user" && selectedAction === "reset" && <span>Are you sure you want to reset this account?</span>}
                    {selectedType === "role" && <span>Are you sure you want to delete this role?</span>}
                </div>
            </YesNoDialog>

            <h3 className={"text-lg flex justify-between items-center text-gray-600 mt-14 mb-1 font-firmaBold"}>
                <span>Roles</span>
                <JavButton
                    title={"Add Role"}
                    padding={"px-16 py-3"}
                    textColor={"text-white"}
                    isLoading={false}
                    onClick={() => setShowCreateRoleDialog(true)}
                />
            </h3>

           <div className="dark:bg-[#242A38] bg-gray-50 dark:border-gray-500 border py-2 mb-4 rounded-lg px-2">
                <Table
                    columns={["#", "Name", "Permissions", "Created At", "Action"]}
                    link={rolesLink}
                    ref={rolesTableRef}
                    tag={"account-management.roles"}
                    fields={[
                        "id",
                        "name",
                        {
                            id: "permissions",
                            render: (content) => {
                                return (
                                    <td className={"text-center"}>
                                        <ul>
                                            {
                                                content.permissions.map((item,index) => {
                                                    return (
                                                        <li className={"text-xs items-center"}>
                                                            {index+ 1}. {item.name}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </td>
                                )
                            }
                        },
                        "created_at"
                    ]}
                />
            </div>

            <h3 className={"text-lg flex justify-between items-center text-gray-600 mt-14 mb-1 font-firmaBold"}>
                <span>User Accounts</span>
                <JavButton
                    title={"Create Account"}
                    padding={"px-16 py-3"}
                    textColor={"text-white"}
                    isLoading={false}
                    onClick={() => {
                        setSelectedAction(null);
                        setSelectedItem(null);
                        setShowCreateAccountDialog(true);
                    }}
                />
            </h3>

            <div className="dark:bg-[#242A38] bg-gray-50 dark:border-gray-500 border py-2 mb-4 rounded-lg px-2">
                <Table
                    ref={userAccountsTableRef}
                    columns={["#", "Name", "Email", "Phone Number", "Role", "Created At", "Action"]}
                    link={link}
                    tag={"account-management.accounts"}
                    fields={[
                        {
                            id: "id",
                            render: (content, index) => (
                                <td className={"text-center"}>{index + 1}</td>
                            )
                        },
                        "name",
                        "email",
                        "phone_number",
                        "role.name",
                        "created_at",
                        {
                            id: "action",
                            render: (content) => (
                                <td>
                                    <div className={`flex justify-center`}>
                                        <JavButton onClick={() => {
                                            setSelectedItem(content);
                                            setSelectedType("user");
                                            setSelectedAction("delete");
                                        }} className={"p-1"} bgColor={"bg-gray-200"}>
                                            <DeleteIcon />
                                        </JavButton>
                                        <JavButton onClick={() => {
                                            setSelectedItem(content);
                                            setSelectedType("user");
                                            setSelectedAction("edit");
                                            setShowCreateAccountDialog(true);
                                        }} className={"p-1 mx-1 text-white"} bgColor={"bg-blue-400"}>
                                            <EditIcon />
                                        </JavButton>
                                        <JavButton onClick={() => {
                                            setSelectedItem(content);
                                            setSelectedType("user");
                                            setSelectedAction("reset");
                                        }} className={"p-1 text-white"} bgColor={"bg-yellow-400"}>
                                            <ResetIcon />
                                        </JavButton>
                                    </div>
                                </td>
                            )
                        }
                    ]}
                />
            </div>
        </div>
    );
}
